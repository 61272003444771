import { Box, Grid, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Suspense } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import Icon from '../Icon';
import LanguagePopover from '../LanguagePopover';
import LoadingIndicator from '../LoadingIndicator';
import ThemeSwitcher from '../ThemeSwitcher';

const InsecureZone = () => {
  const theme = useTheme();
  const location = useLocation();

  const isVerifyPage = location.pathname.startsWith('/verify/');
  const isChangePasswordPage = location.pathname.startsWith('/change-password/');
  const isInvitationPage = location.pathname.startsWith('/register/');

  return (
    <>
      {(isVerifyPage || isChangePasswordPage || isInvitationPage) && (
        <Stack position="absolute" spacing={3} py={3} px={2.5} flexShrink={0}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Icon name="logo" width="152px" height="44px" />
          </Stack>
        </Stack>
      )}
      <Box position="absolute" top={16} right={24} zIndex={theme.zIndex.appBar + 1}>
        <Box display="flex" alignItems="center">
          <Box mr={2}>
            <ThemeSwitcher />
          </Box>
          <LanguagePopover />
        </Box>
      </Box>
      <Suspense fallback={<LoadingIndicator />}>
        <Grid container height="100vh" alignContent="center" justifyContent="center" px={2} position="relative">
          <Grid item xs={12} md={6} lg={4} xl={2.8}>
            {!isVerifyPage && !isChangePasswordPage && !isInvitationPage && (
              <Box display="flex" justifyContent="center" mb={8}>
                <Icon name="logo" width="217px" height="60px" />
              </Box>
            )}
            <Outlet />
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
};

export default InsecureZone;
