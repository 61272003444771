import { createApi } from '@reduxjs/toolkit/query/react';
import * as qrCode from 'qrcode';
import { axiosBaseQuery } from 'src/common/baseQuery';
import { authApiUrl } from 'src/config';

type ChangePasswordParams = {
  oldPassword: string;
  newPassword: string;
};

export const settingsApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: authApiUrl }),
  reducerPath: 'settingsApi',
  endpoints: (build) => ({
    resendVerificationEmail: build.mutation<void, void>({
      query() {
        return {
          url: '/resend-verify',
          method: 'POST',
        };
      },
    }),
    activateMFA: build.mutation<void, string>({
      query(token) {
        return {
          url: '/mfa/approve-mfa-secret',
          method: 'POST',
          data: {
            token,
          },
        };
      },
    }),
    changePassword: build.mutation<void, ChangePasswordParams>({
      query(data) {
        return {
          url: '/change-password',
          method: 'POST',
          data,
        };
      },
    }),
    requestMFASecret: build.mutation<string, void>({
      query() {
        return {
          url: '/mfa/mfa-secret',
          method: 'GET',
        };
      },
      transformResponse: async (response: { qr: string }) => await qrCode.toString(response.qr),
    }),
  }),
});

export const {
  useResendVerificationEmailMutation,
  useRequestMFASecretMutation,
  useActivateMFAMutation,
  useChangePasswordMutation,
} = settingsApi;
