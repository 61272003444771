import { Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import Cases from '../../Illustrations/Cases';

type CasesProps = {
  email: string;
  firstName: string;
  lastName: string;
};

const CasesWidget = ({ email, firstName, lastName }: CasesProps) => {
  const { t } = useTranslation(['sidebar']);

  return (
    <Stack spacing={3} px={5} pb={5} mt={10} width={1} textAlign="center" display="block">
      <Cases sx={{ width: 1 }} />

      <div>
        <Typography
          gutterBottom
          variant="subtitle1"
          overflow="hidden"
          display="-webkit-box"
          sx={{ WebkitLineClamp: '3', lineClamp: '3', WebkitBoxOrient: 'vertical' }}
        >
          {t('helloUser', {
            user: firstName && firstName ? `${firstName} ${lastName}` : email,
          })}
        </Typography>
        <Typography variant="body2" color="text.secondary" whiteSpace="pre-line">
          {t('needHelp')}
        </Typography>
      </div>

      <Button variant="contained" component={RouterLink} to={'/support-requests'}>
        {t('chatWithUs')}
      </Button>
    </Stack>
  );
};

export default CasesWidget;
