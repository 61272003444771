import { createApi } from '@reduxjs/toolkit/query/react';
import { parseISO, subDays } from 'date-fns';
import { axiosBaseQuery } from 'src/common/baseQuery';
import { mainApiUrl } from 'src/config';

import { Currency, PaginatedData } from '../common/types';
import { Speciality, UserContractType } from '../common/types/contract.types';

export type ContractData = {
  jobTitle: string;
  speciality: Speciality;
  documentTemplateUidList: string[];
};

type InviteEmployeeRequest = {
  email: string;
  agreementData: ContractData;
  contractType: UserContractType;
};

type Invitation = {
  email: string;
  expirationDate: string;
  invitedUser: never;
  jobTitle: string;
  status: 'CREATED';
  uid: string;
  contractType: UserContractType;
  speciality: Speciality;
};

export type Permit = {
  uid: string;
  status: 'DECLINED' | 'CREATED' | 'REQUESTED' | 'EXPIRED' | 'PROCESSING' | 'VERIFIED';
  activeUntil: string;
  declineReasonCode: null;
  deleted: false;
  createdAt: string;
};

type EmployeeProfile = {
  uid: string;
  firstName: string;
  secondName?: string;
  lastName: string;
  birthDate: string;
  cityOfBirth: string;
  cityOrPlace: string | null;
  province: string | null;
  area: string | null;
  district: string | null;
  postalCode: string | null;
  street: string | null;
  buildingNumber: string | null;
  appartmentNumber: string | null;
  verifiedNationality: string | null;
  nationality: {
    uid: string;
    name: string;
  };
  taxOffice: {
    uid: string;
    name: string;
  } | null;
  pesel: string | null;
};

export enum KycStatus {
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
}

type KycCheckSuccess = {
  status: KycStatus.SUCCESS;
  validUntilDate: string;
  alpha3CountryCode: string;
  passportNumber: string;
};

type KycCheckFail = {
  status: KycStatus.FAIL;
};

export type KycCheck = (KycCheckSuccess | KycCheckFail) & {
  uid: string;
  processingStartDate: string;
  processingEndDate: string;
  updatedAt: string;
  createdAt: string;
};

type EmployeeBase = {
  contractType: UserContractType;
  cooperationAgreementSigned: boolean;
  createdAt: string;
  identityVerified: boolean;
  residencePermitActive: boolean;
  uid: string;
  updatedAt: string;
  workPermitActive: boolean;
  yearlyPayoutAmount: number;
};

type EmployeeCalculatedData = {
  jobTitle: string | null;
  speciality: Speciality | null;
};

export type ServiceSubscription = {
  subscription: {
    activeUntil: string;
    createdAt: string;
    lastPayment: string;
    nextPayment: string;
    status: 'ACTIVE' | 'DEACTIVATED';
    uid: string;
  };
  uid: string;
};

export type EmployeeListItem = {
  uid: string;
  jobTitle: string | null;
  email: string;
  firstName?: string;
  lastName?: string;
  kyc: KycCheck | null;
  yearlyPayoutAmount: number | null;
  contractType: UserContractType;
  speciality: Speciality | null;
  subscription: ServiceSubscription | null;
  workPermit: Permit | null;
  residencePermit: Permit | null;
  isAbleToReceivePayout: boolean;
};

type Employee = EmployeeBase & {
  ClientProfile: EmployeeProfile | null;
  User: {
    uid: string;
    email: string;
    KycCheck: KycCheck | null;
  };
  ResidencePermit: Array<Permit>;
  WorkPermit: Array<Permit>;
  ServiceSubscription: null | {
    subscription: {
      activeUntil: string;
      createdAt: string;
      lastPayment: string;
      nextPayment: string;
      status: 'ACTIVE';
      uid: string;
    };
    uid: string;
  };
  DocumentAgreement: Array<{
    type: 'COOPERATION_AGREEMENT';
    status: 'REJECTED' | 'CANCELED' | 'CREATED' | 'INITIATED' | 'PROCESSING' | 'SIGNED';
    jobTitle: string;
    speciality: Speciality;
    createdAt: string;
  }>;
};

type EmployeeBankAccount = {
  counterpartyUid: string;
  currency: Currency;
};

export const findAppropriatePermit = (permits: Permit[]): Permit | null => {
  const now = new Date();

  const sortedPermits = permits
    .filter((permit) => permit.status === 'VERIFIED' || permit.status === 'EXPIRED')
    .sort((a, b) => {
      const aDate = new Date(a.activeUntil);
      const bDate = new Date(b.activeUntil);

      if (a.status === 'VERIFIED' && b.status === 'VERIFIED') {
        if (aDate > now && bDate > now) {
          return bDate.getTime() - aDate.getTime();
        }
        return aDate > now ? -1 : 1;
      } else if (a.status === 'EXPIRED' && b.status === 'EXPIRED') {
        return bDate.getTime() - aDate.getTime();
      } else {
        return a.status === 'VERIFIED' ? -1 : 1;
      }
    });

  return sortedPermits.length > 0 ? sortedPermits[0] : null;
};

export const employeesApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: mainApiUrl }),
  reducerPath: 'employeesApi',
  tagTypes: ['Invitations', 'ActiveUsers'],
  endpoints: (build) => ({
    getInvitations: build.query<PaginatedData<Invitation>, void>({
      providesTags: ['Invitations'],
      query() {
        return {
          url: '/invitation',
          method: 'GET',
          params: {
            filter: [
              {
                field: 'status',
                type: '=',
                value: 'CREATED',
              },
              {
                field: 'expirationDate',
                type: '>',
                value: new Date().toISOString(),
              },
            ],
          },
        };
      },
    }),
    getEmployeeBankAccounts: build.query<PaginatedData<EmployeeBankAccount>, string | undefined>({
      query(uid) {
        return {
          url: `/payment/list-client-payout-accounts/${uid}`,
          method: 'GET',
        };
      },
    }),
    getEmployees: build.query<Array<EmployeeListItem>, { currentUid?: string; search?: string } | null>({
      providesTags: (result: EmployeeListItem[] | undefined) => {
        if (result) {
          return result.map(({ uid }) => ({ type: 'ActiveUsers' as const, id: uid }));
        } else {
          return [{ type: 'ActiveUsers', id: 'LIST' }];
        }
      },
      query(data) {
        const filter = [];

        if (data?.currentUid) {
          filter.push({
            field: 'uid',
            type: '!=',
            value: data.currentUid,
          });
        }

        if (data?.search) {
          filter.push({
            field: '!search',
            type: '=',
            value: data.search,
          });
        }

        return {
          url: '/client/detailed-list?includePayoutAmount=true',
          method: 'GET',
          params: {
            filter,
          },
        };
      },
      transformResponse(response: PaginatedData<Employee>) {
        const result: Array<EmployeeListItem> = [];

        response.data.items.forEach((item) => {
          const profile = item.DocumentAgreement.filter(
            (document) =>
              document.status !== 'REJECTED' &&
              document.status !== 'CANCELED' &&
              document.type === 'COOPERATION_AGREEMENT',
          ).sort((a, b) => {
            return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
          });

          if (item.ServiceSubscription?.subscription.activeUntil) {
            const date = parseISO(item.ServiceSubscription.subscription.activeUntil);
            item.ServiceSubscription.subscription.activeUntil = subDays(date, 5).toISOString();
          }

          result.push({
            uid: item.uid,
            email: item.User.email,
            jobTitle: profile.length ? profile[0].jobTitle : null,
            firstName: item.ClientProfile?.firstName,
            lastName: item.ClientProfile?.lastName,
            yearlyPayoutAmount: item.yearlyPayoutAmount,
            contractType: item.contractType,
            kyc: item.User.KycCheck,
            speciality: profile.length ? profile[0].speciality : null,
            subscription: item.ServiceSubscription,
            workPermit: findAppropriatePermit(item.WorkPermit),
            residencePermit: findAppropriatePermit(item.ResidencePermit),
            isAbleToReceivePayout:
              item.identityVerified &&
              item.workPermitActive &&
              item.residencePermitActive &&
              item.ServiceSubscription?.subscription.status === 'ACTIVE',
          });
        });

        return result;
      },
    }),
    getEmployeeById: build.query<Employee & EmployeeCalculatedData, string | undefined>({
      providesTags: (result) => (result ? [{ type: 'ActiveUsers' as const, id: result.uid }] : ['ActiveUsers']),
      query(uid) {
        return {
          url: '/client/detailed-list',
          method: 'GET',
          params: {
            filter: [
              {
                field: 'uid',
                type: '=',
                value: uid,
              },
            ],
          },
        };
      },
      transformResponse(response: PaginatedData<Employee>) {
        const item = response.data.items[0];

        const profile = item.DocumentAgreement.filter(
          (document) =>
            document.status !== 'REJECTED' &&
            document.status !== 'CANCELED' &&
            document.type === 'COOPERATION_AGREEMENT',
        ).sort((a, b) => {
          return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
        });

        return {
          ...item,
          jobTitle: profile.length ? profile[0].jobTitle : null,
          speciality: profile.length ? profile[0].speciality : null,
        };
      },
    }),
    inviteEmployee: build.mutation<void, InviteEmployeeRequest>({
      query(params) {
        return {
          url: '/invitation',
          method: 'POST',
          data: {
            ...params,
            type: 'CLIENT',
          },
        };
      },
    }),
    getActiveUsersForOrganisation: build.query<{ data: { hasActive: boolean } }, null>({
      providesTags: ['ActiveUsers'],
      query() {
        return {
          url: '/invitation/has-active-users',
          method: 'GET',
        };
      },
    }),
    revokeInvitation: build.mutation<void, string>({
      query(uid) {
        return {
          url: `/invitation/cancel/${uid}`,
          method: 'POST',
        };
      },
    }),
    resendInvitation: build.mutation<void, string>({
      invalidatesTags: ['Invitations'],
      query(uid) {
        return {
          url: `/invitation/resend/${uid}`,
          method: 'POST',
        };
      },
    }),
  }),
});

export const {
  useInviteEmployeeMutation,
  useGetInvitationsQuery,
  useGetEmployeeByIdQuery,
  useRevokeInvitationMutation,
  useGetActiveUsersForOrganisationQuery,
  useGetEmployeesQuery,
  useResendInvitationMutation,
  useGetEmployeeBankAccountsQuery,
} = employeesApi;
