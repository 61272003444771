import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'src/common/baseQuery';
import { mainApiUrl } from 'src/config';

import { BaseDictionary, Currency, NationalitiesList, Option } from '../common/types';
import { Speciality, UserContractType } from '../common/types/contract.types';

export type ProfileAddress = {
  cityOrPlace: string;
  province: string;
  district: string;
  area: string;
  postalCode: string;
  street: string;
  buildingNumber: string;
  appartmentNumber: string;
  taxOffice: Option | null;
};

export type UpdateProfileAddressPayload = Omit<ProfileAddress, 'taxOffice'> & {
  taxOfficeUid: string;
};

type ProfilePersonalData = {
  firstName: string;
  secondName?: string;
  lastName: string;
  birthDate: Date;
  cityOfBirth: string;
};

export type ProfileData = {
  pesel?: string;
  passportNumber?: string;
  contractType: UserContractType;
  nationality: {
    uid: string;
    name: string;
  };
  taxOffice: {
    uid: string;
    name: string;
  };
  registrationAddressCountry: {
    name: string;
    uid: string;
  };
} & ProfilePersonalData &
  ProfileAddress;

export type CreateProfileData = {
  nationalityUid: string;
  contractType?: UserContractType;
  administrationShouldBeInformed: boolean;
  pesel?: string;
} & ProfilePersonalData;

export type ContractData = {
  jobTitle: string;
  speciality: Speciality;
  documentTemplateUidList: string[];
};

export type ContractInfo = {
  cooperationAgreement: ContractData;
};

export type ProfileForm = CreateProfileData &
  ProfileAddress &
  ContractData & { userHasAddressInPoland?: boolean; role: 'USER' | 'MANAGER' };

type CreateProfileRequest = {
  profileData: CreateProfileData & { clientProfileAddress?: Partial<ProfileAddress> };
  agreementData: ContractData;
};

export type NewBankAccountRequest = {
  name: string;
  currency: Currency;
  iban: string;
  bic: string;
};

type BankAccount = {
  accountData: {
    bic: string;
    createdAt: string;
    iban: string;
    uid: string;
  };
  counterpartyUid: string;
  currency: Currency;
  name: string;
};

export const profileApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: mainApiUrl }),
  reducerPath: 'profileApi',
  tagTypes: ['BankAccounts', 'Profile', 'WorkPermit', 'ResidencePermit'],
  endpoints: (build) => ({
    createProfile: build.mutation<void, CreateProfileRequest>({
      query(data) {
        return {
          url: '/client/profile-with-agreement/initiate',
          method: 'POST',
          data,
        };
      },
    }),
    deleteBankAccount: build.mutation<void, string>({
      invalidatesTags: ['BankAccounts'],
      query(uid) {
        return {
          url: `/payment/delete-payout-account/${uid}`,
          method: 'DELETE',
        };
      },
    }),
    updateEmployeeProfile: build.mutation<void, CreateProfileData & { clientProfileAddress?: Partial<ProfileAddress> }>(
      {
        invalidatesTags: ['Profile'],
        query(data) {
          return {
            url: '/client-profile',
            method: 'POST',
            data,
          };
        },
      },
    ),
    getNationalities: build.query<NationalitiesList, void>({
      query() {
        return {
          url: '/nationality/list',
          method: 'GET',
          params: {
            limit: 500,
            offset: 0,
          },
        };
      },
    }),
    getProfile: build.query<{ data: ProfileData | null }, null>({
      providesTags: ['Profile'],
      query() {
        return {
          url: '/client-profile',
          method: 'GET',
        };
      },
      transformResponse: (response: { data: ProfileData | null }) => {
        if (!response.data) {
          return {
            data: null,
          };
        }

        return {
          data: {
            ...response.data,
            registrationAddressCountryUid: response.data.registrationAddressCountry.uid,
            nationalityUid: response.data.nationality.uid,
          },
        };
      },
    }),
    getContractData: build.query<{ data: ContractInfo }, void>({
      query() {
        return {
          url: '/document-agreement/cooperation-agreement',
          method: 'GET',
        };
      },
    }),
    getTaxOffices: build.query<BaseDictionary, void>({
      query() {
        return {
          url: '/tax-office',
          method: 'GET',
          params: {
            limit: 500,
          },
        };
      },
    }),
    getBankAccounts: build.query<{ data: { items: Array<BankAccount> } }, void>({
      providesTags: ['BankAccounts'],
      query() {
        return {
          url: '/payment/list-payout-accounts',
          method: 'GET',
        };
      },
    }),
    createBankAccount: build.mutation<void, NewBankAccountRequest>({
      invalidatesTags: ['BankAccounts'],
      query(data) {
        return {
          url: '/payment/create-payout-account',
          method: 'POST',
          data,
        };
      },
    }),
    updatePesel: build.mutation<void, string>({
      invalidatesTags: ['Profile'],
      query(pesel) {
        return {
          url: '/client-profile',
          method: 'PATCH',
          data: {
            pesel,
          },
        };
      },
    }),
    updateAddress: build.mutation<void, UpdateProfileAddressPayload>({
      invalidatesTags: ['Profile'],
      query(data) {
        return {
          url: '/client-profile',
          method: 'PATCH',
          data: {
            clientProfileAddress: data,
          },
        };
      },
    }),
  }),
});

export const {
  useGetProfileQuery,
  useGetTaxOfficesQuery,
  useCreateProfileMutation,
  useGetContractDataQuery,
  useCreateBankAccountMutation,
  useGetBankAccountsQuery,
  useGetNationalitiesQuery,
  useUpdateAddressMutation,
  useUpdatePeselMutation,
  useUpdateEmployeeProfileMutation,
  useDeleteBankAccountMutation,
} = profileApi;
