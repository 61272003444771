import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'src/common/baseQuery';
import { mainApiUrl } from 'src/config';

import type { Document } from '../../types';

export type TemplatesList = {
  data: {
    items: Array<Document>;
  };
};

export const contractManagerApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: mainApiUrl }),
  reducerPath: 'contractManagerApi',
  endpoints: (build) => ({
    getTemplates: build.query<TemplatesList, null>({
      query() {
        return {
          url: '/document-template',
          method: 'GET',
        };
      },
    }),
  }),
});

export const { useGetTemplatesQuery } = contractManagerApi;
