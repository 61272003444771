import { Box, MenuItem, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { MouseEvent, useState } from 'react';

import { allLangs } from '../../config';
import useConfiguration from '../hooks/useConfiguration';
import Icon, { IconName } from './Icon';
import MenuPopover from './MenuPopover';

export default function LanguagePopover() {
  const { currentLanguage, onChangeLanguage } = useConfiguration();
  const theme = useTheme();

  const [open, setOpen] = useState<HTMLElement | null>(null);

  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleChangeLang = (newLang: string) => {
    onChangeLanguage(newLang);
    handleClose();
  };

  return (
    <>
      <Box
        onClick={handleOpen}
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="44px"
        width="52px"
        sx={{
          bgcolor: theme.palette.grey[500_8],
          borderRadius: '7px',
          cursor: 'pointer',
        }}
      >
        <Icon
          width="32px"
          height="24px"
          name={currentLanguage.icon as IconName}
          sx={{
            display: 'block',
            '& svg': {
              borderRadius: '3px',
              boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.16)',
            },
          }}
        />
      </Box>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 180,
          '& .MuiMenuItem-root': {
            px: 1,
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Stack spacing={0.75}>
          {allLangs.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === currentLanguage.value}
              onClick={() => handleChangeLang(option.value)}
            >
              <Icon
                width="24px"
                height="18px"
                name={option.icon as IconName}
                mr={1}
                sx={{
                  display: 'block',
                  '& svg': {
                    borderRadius: '3px',
                    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.16)',
                  },
                }}
              />
              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  );
}
