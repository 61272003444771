import { AppBar, Box, Button, Stack, Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';
import { memo } from 'react';
import { useAppSelector } from 'src/common/hooks/reduxHooks';
import useOffSetTop from 'src/common/hooks/useOffsetTop';
import useResponsive from 'src/common/hooks/useResponsive';
import cssStyles from 'src/common/utils/cssStyles';

import Icon from '../Icon';
import LanguagePopover from '../LanguagePopover';
import ThemeSwitcher from '../ThemeSwitcher';
import AccountPopover from './components/AccountPopover';

type RootStyleProps = {
  isCollapse: boolean;
  isOffset: boolean;
};

const RootStyled = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'isCollapse' && prop !== 'isOffset',
})<RootStyleProps>(({ isCollapse, isOffset, theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: 'none',
  backgroundImage: 'none',
  height: 64,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('lg')]: {
    height: 92,
    width: 'calc(100% - 281px)',
    ...(isCollapse && {
      width: 'calc(100% - 88px)',
    }),
    ...(isOffset && {
      height: 60,
    }),
  },
}));

type NavbarProps = {
  onOpenSidebar: VoidFunction;
  isCollapse?: boolean;
};

const Navbar = ({ onOpenSidebar, isCollapse = false }: NavbarProps) => {
  const isOffset = useOffSetTop(92);
  const { email, firstName, lastName } = useAppSelector((state) => state.common);
  const isDesktop = useResponsive('up', 'lg');

  return (
    <RootStyled isCollapse={isCollapse} isOffset={isOffset}>
      <Toolbar
        sx={{
          minHeight: '100% !important',
          px: { lg: 5 },
        }}
      >
        {!isDesktop && (
          <Button onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Icon name="sidebarCollapse" width="30px" height="30px" />
          </Button>
        )}

        <Box flexGrow={1} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          <ThemeSwitcher />
          <LanguagePopover />
          <AccountPopover email={email} firstName={firstName} lastName={lastName} />
        </Stack>
      </Toolbar>
    </RootStyled>
  );
};

export default memo(Navbar);
