import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Box, Divider, MenuItem, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { logout } from 'src/common/services/authService';

import MenuPopover from '../../MenuPopover';
import MyAvatar from './MyAvatar';

type AccountPopoverProps = {
  email: string;
  firstName: string;
  lastName: string;
};

const AccountPopover = ({ firstName, lastName, email }: AccountPopoverProps) => {
  const [open, setOpen] = useState<HTMLElement | null>(null);
  const theme = useTheme();
  const { t } = useTranslation(['common']);

  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <Box
        onClick={handleOpen}
        display="flex"
        alignItems="center"
        bgcolor={theme.palette.grey[500_8]}
        p={1}
        borderRadius="8px"
        sx={{
          cursor: 'pointer',
        }}
      >
        <MyAvatar displayName={firstName && lastName ? `${firstName} ${lastName}` : email} />
        <Box
          typography="subtitle2"
          color={theme.palette.text.primary}
          mx={1}
          maxWidth={250}
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
        >
          {firstName && lastName ? `${firstName} ${lastName}` : email}
        </Box>
        {open ? (
          <ExpandLess htmlColor={theme.palette.text.secondary} />
        ) : (
          <ExpandMore htmlColor={theme.palette.text.secondary} />
        )}
      </Box>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        arrow="top-center"
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          {firstName && lastName ? (
            <Typography variant="subtitle2" noWrap>
              {firstName} {lastName}
            </Typography>
          ) : null}
          <Typography variant="body2" color="text.secondary" noWrap>
            {email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          <MenuItem to="/profile" component={RouterLink} onClick={handleClose}>
            {t('myProfile')}
          </MenuItem>
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          <MenuItem to="/settings" component={RouterLink} onClick={handleClose}>
            {t('settings')}
          </MenuItem>
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={() => logout()} sx={{ m: 1 }}>
          {t('logout')}
        </MenuItem>
      </MenuPopover>
    </>
  );
};

export default AccountPopover;
