import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'src/common/baseQuery';
import { mainApiUrl } from 'src/config';

export const contractsReviewApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: mainApiUrl }),
  reducerPath: 'contractsReviewApi',
  endpoints: (build) => ({
    markContractsReviewed: build.mutation<void, void>({
      query() {
        return {
          url: '/organization-life-stage/contracts-reviewed',
          method: 'POST',
        };
      },
    }),
  }),
});

export const { useMarkContractsReviewedMutation } = contractsReviewApi;
