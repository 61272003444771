import { Navigate, Outlet } from 'react-router-dom';

import { useAppSelector } from '../hooks/reduxHooks';

export const ManagerZone = () => {
  const role = useAppSelector((state) => state.common.role);
  const organizationStructure = useAppSelector((state) => state.common.organizationStructure);

  if (role === 'USER') {
    return <Navigate to="/404" replace />;
  }

  if (location.pathname.startsWith('/employees') && organizationStructure === 'SELF_EMPLOYEE') {
    return <Navigate to="/404" replace />;
  }

  return <Outlet />;
};
