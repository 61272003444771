import { createApi } from '@reduxjs/toolkit/query/react';
import { parseISO, subDays } from 'date-fns';
import { axiosBaseQuery } from 'src/common/baseQuery';
import { mainApiUrl } from 'src/config';

import { findAppropriatePermit, Permit } from '../../../employees/employees.service';
import { PaginatedData } from '../../types';

type PassportData = {
  passportNumber: string;
  status: 'CREATED' | 'SUCCESS';
  uid: string;
  validUntilDate: string;
};

type SubscriptionInfo = {
  activeUntil: string | null;
  amount: string;
  currency: string;
  lastPayment: string | null;
  nextPayment: null | string;
  createdAt: string;
  status: 'DEACTIVATED' | 'ACTIVE';
  uid: string;
};

export const statusBarApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: mainApiUrl }),
  tagTypes: ['WorkPermit', 'ResidencePermit', 'Subscription'],
  reducerPath: 'statusBarApi',
  endpoints: (build) => ({
    getPassportState: build.query<{ data: PassportData }, void>({
      query() {
        return {
          url: '/kyc/latest',
          method: 'GET',
        };
      },
    }),
    getSubscriptionState: build.query<PaginatedData<SubscriptionInfo>, null>({
      providesTags: ['Subscription'],
      query() {
        return {
          url: '/subscription',
          method: 'GET',
          params: {
            filter: [
              {
                field: 'NOT.ServiceSubscription',
                type: 'raweqnull',
                value: 'null',
              },
            ],
            order: [{ field: 'createdAt', dir: 'desc' }],
          },
        };
      },
      transformResponse(response: PaginatedData<SubscriptionInfo>) {
        response.data.items.forEach((item) => {
          if (item.activeUntil) {
            const date = parseISO(item.activeUntil);
            item.activeUntil = subDays(date, 5).toISOString();
          }
        });
        return response;
      },
    }),
    getWorkPermitInfo: build.query<(Permit & { isNewSubmitted: boolean }) | null, void>({
      providesTags: ['WorkPermit'],
      query() {
        return {
          url: '/work-permit',
          method: 'GET',
        };
      },
      transformResponse(response: { data: Array<Permit> }) {
        const actualPermit = findAppropriatePermit(response.data);

        if (!actualPermit) {
          return null;
        }

        return {
          ...actualPermit,
          isNewSubmitted: response.data.some((permit) => permit.status === 'CREATED'),
        };
      },
    }),
    reApplyForWorkPermit: build.mutation<{ uid: string }, string[]>({
      query(fileUploadUidList) {
        return {
          url: '/work-permit/re-apply',
          method: 'POST',
          data: {
            fileUploadUidList,
          },
        };
      },
      invalidatesTags: ['WorkPermit'],
    }),
    reApplyForResidencePermit: build.mutation<{ uid: string }, string[]>({
      query(fileUploadUidList) {
        return {
          url: '/residence-permit/re-apply',
          method: 'POST',
          data: {
            fileUploadUidList,
          },
        };
      },
      invalidatesTags: ['ResidencePermit'],
    }),
    getResidencePermitInfo: build.query<(Permit & { isNewSubmitted: boolean }) | null, void>({
      providesTags: ['ResidencePermit'],
      query() {
        return {
          url: '/residence-permit',
          method: 'GET',
        };
      },
      transformResponse(response: { data: Array<Permit> }) {
        const actualPermit = findAppropriatePermit(response.data);

        if (!actualPermit) {
          return null;
        }

        return {
          ...actualPermit,
          isNewSubmitted: response.data.some((permit) => permit.status === 'CREATED'),
        };
      },
    }),
    getSupportRequests: build.query<PaginatedData<unknown>, void>({
      query() {
        return {
          url: '/support-case/list',
          method: 'GET',
          params: {
            filter: [
              {
                field: 'mode',
                type: '=',
                value: 'CHAT',
              },
              {
                field: 'source',
                type: '=',
                value: 'ADMIN',
              },
              {
                field: 'status',
                type: '=',
                value: 'OPEN',
              },
            ],
          },
        };
      },
    }),
  }),
});

export const {
  useGetPassportStateQuery,
  useGetWorkPermitInfoQuery,
  useGetResidencePermitInfoQuery,
  useGetSubscriptionStateQuery,
  useGetSupportRequestsQuery,
  useReApplyForWorkPermitMutation,
  useReApplyForResidencePermitMutation,
} = statusBarApi;
