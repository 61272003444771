import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { useAppSelector } from '../hooks/reduxHooks';

export const OnboardingZone = () => {
  const { onboarding, role, organizationStructure } = useAppSelector((state) => state.common);
  const location = useLocation();

  if (location.pathname === '/contracts-review' && onboarding.contractsReviewed) {
    return <Navigate to="/404" replace />;
  }

  if (
    location.pathname === '/prepaid' &&
    (onboarding.initialAmountPaid === 'PROGRESS' || onboarding.initialAmountPaid === 'DONE')
  ) {
    return <Navigate to="/404" replace />;
  }

  if (location.pathname === '/kyc') {
    if (onboarding.verificationDone === 'PROGRESS' || onboarding.verificationDone === 'DONE')
      return <Navigate to="/" replace />;

    if (role === 'USER' && !onboarding.initialProfileFill) {
      return <Navigate to="/404" replace />;
    }

    if (
      role === 'MANAGER' &&
      ((organizationStructure === 'COMPANY' && !onboarding.firstEmployeeInvited) ||
        (organizationStructure === 'SELF_EMPLOYEE' && onboarding.initialAmountPaid !== 'DONE'))
    ) {
      return <Navigate to="/404" replace />;
    }
  }

  if (location.pathname === '/permits-check') {
    if (onboarding.permitsVerificationDone === 'PROGRESS' || onboarding.permitsVerificationDone === 'DONE')
      return <Navigate to="/404" replace />;

    if (onboarding.verificationDone !== 'DONE') return <Navigate to="/404" replace />;
  }

  return <Outlet />;
};
