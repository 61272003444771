import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import InsecureZone from 'src/common/components/InsecureZone';
import NotFound from 'src/common/components/NotFound';
import NotificationsProvider from 'src/common/components/NotificationsProvider';
import SecureZone from 'src/common/components/SecureZone';
import { SnackbarUtilsConfigurator } from 'src/common/utils/snackbarUtils';

import LoadingIndicator from './common/components/LoadingIndicator';
import { ManagerZone } from './common/components/ManagerZone';
import { OnboardingZone } from './common/components/OnboardingZone';
import useConfiguration from './common/hooks/useConfiguration';

const ContractsReview = lazy(() => import('./contractsReview'));
const Settings = lazy(() => import('./settings'));
const Overview = lazy(() => import('./overview'));
const Employees = lazy(() => import('./employees'));
const NewEmployee = lazy(() => import('./employees/newEmployee'));
const EmployeeDetails = lazy(() => import('./employees/employeeDetails'));
const Documents = lazy(() => import('./documents'));
const Finances = lazy(() => import('./finances'));
const FinancesSummary = lazy(() => import('./finances/summary'));
const FinancesTransactions = lazy(() => import('./finances/transactions'));
const ProfileGeneral = lazy(() => import('./profile/components/General'));
const ProfileBankAccounts = lazy(() => import('./profile/components/BankAccounts'));
const Profile = lazy(() => import('./profile'));
const PrepaidBalance = lazy(() => import('./prepaidBalance'));
const Clients = lazy(() => import('./clients'));
const NewClient = lazy(() => import('./clients/newClient'));
const ClientDetails = lazy(() => import('./clients/clientDetails'));
const Invoices = lazy(() => import('./invoices'));
const NewInvoice = lazy(() => import('./invoices/newInvoice'));
const InvoiceDetails = lazy(() => import('./invoices/invoiceDetails'));
const IdentityVerification = lazy(() => import('./kyc/IdentityVerification'));
const PermitsVerification = lazy(() => import('./kyc/PermitsVerification'));
const SupportRequests = lazy(() => import('./supportRequests'));
const SignIn = lazy(() => import('./auth/SignIn'));
const SignUp = lazy(() => import('./auth/SignUp'));
const SupportRequest = lazy(() => import('./supportRequests/request'));
const Payout = lazy(() => import('./finances/payout'));
const AcceptInvitation = lazy(() => import('./auth/AcceptInvitation'));
const VerifyEmail = lazy(() => import('./auth/VerifyEmail'));
const ForgotPassword = lazy(() => import('./auth/ForgotPassword'));
const NewPassword = lazy(() => import('./auth/NewPassword'));
import en from 'date-fns/locale/en-US';
import ru from 'date-fns/locale/ru';

function App() {
  const { currentLanguage } = useConfiguration();

  return (
    <NotificationsProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={currentLanguage.value === 'en' ? en : ru}>
        <SnackbarUtilsConfigurator />
        <Suspense fallback={<LoadingIndicator />}>
          <Routes>
            <Route element={<SecureZone />}>
              <Route path="/" index element={<Overview />} />
              <Route path="/documents/:section?" element={<Documents />} />
              <Route path="/profile" element={<Profile />}>
                <Route index element={<ProfileGeneral />} />
                <Route path="bank-accounts" element={<ProfileBankAccounts />} />
              </Route>
              <Route path="/settings" element={<Settings />} />
              <Route path="/support-requests" element={<SupportRequests />} />
              <Route path="/support-requests/:id" element={<SupportRequest />} />

              <Route element={<OnboardingZone />}>
                <Route element={<ManagerZone />}>
                  <Route path="/contracts-review" element={<ContractsReview />} />
                  <Route path="/prepaid" element={<PrepaidBalance />} />
                </Route>
                <Route path="/permits-check" element={<PermitsVerification />} />
                <Route path="/kyc" element={<IdentityVerification />} />
              </Route>

              <Route element={<ManagerZone />}>
                <Route path="/clients" element={<Clients />} />
                <Route path="/clients/:id" element={<ClientDetails />} />
                <Route path="/clients/new" element={<NewClient />} />
                <Route path="/invoices" element={<Invoices />} />
                <Route path="/invoices/:id" element={<InvoiceDetails />} />
                <Route path="/invoices/new" element={<NewInvoice />} />
                <Route path="/finances" element={<Finances />}>
                  <Route index element={<FinancesSummary />} />
                  <Route path="transactions" element={<FinancesTransactions />} />
                </Route>
                <Route path="/finances/payout" element={<Payout />} />
                <Route path="/employees" element={<Employees />} />
                <Route path="/employees/:id" element={<EmployeeDetails />} />
                <Route path="/employees/new" element={<NewEmployee />} />
              </Route>
            </Route>
            <Route element={<InsecureZone />}>
              <Route path="/signIn" element={<SignIn />} />
              <Route path="/forgot" element={<ForgotPassword />} />
              <Route path="/signUp" element={<SignUp />} />
              <Route path="/register/:token" element={<AcceptInvitation />} />
              <Route path="/verify/:token" element={<VerifyEmail />} />
              <Route path="/change-password/:token" element={<NewPassword />} />
            </Route>
            <Route path="/404" element={<NotFound />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </LocalizationProvider>
    </NotificationsProvider>
  );
}

export default App;
