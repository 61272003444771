import { styled } from '@mui/material/styles';
import axios from 'axios';
import { Suspense, useCallback, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useLazyGetUserAndOrgQuery } from 'src/common/apis';
import { logout, requestInterceptor, responseErrorInterceptor } from 'src/common/services/authService';

import { SSEContext } from '../../contexts/SSEContext';
import useSSE from '../../hooks/useSSE';
import LoadingIndicator from '../LoadingIndicator';
import Navbar from '../Navbar';
import SideBar from '../SideBar';

type MainStyleProps = {
  collapseClick?: boolean;
};

const MainStyled = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})<MainStyleProps>(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingTop: 88,
  paddingBottom: 88,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 100,
    height: '100%',
    paddingBottom: 0,
    width: 'calc(100% - 280px)',
    marginLeft: 280,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: 88,
    }),
  },
}));

const SecureZone = () => {
  const [isLoading, setLoading] = useState(true);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [getUserAndOrg] = useLazyGetUserAndOrgQuery();
  const { eventSource } = useSSE(!isLoading);
  console.log('render SecureZone');

  const authenticate = async () => {
    axios.interceptors.request.use(requestInterceptor);
    axios.interceptors.response.use((response) => response, responseErrorInterceptor);

    await getUserAndOrg().unwrap();
  };

  useEffect(() => {
    authenticate()
      .then(() => setLoading(false))
      .catch((err) => {
        console.error(err);
        logout();
      });
  }, []);

  const handleSidebarOpen = useCallback(() => {
    setSidebarOpen(true);
  }, []);

  const handleSidebarClose = useCallback(() => {
    setSidebarOpen(false);
  }, []);

  if (isLoading) return <LoadingIndicator />;

  return (
    <SSEContext.Provider value={eventSource?.current}>
      <SideBar isOpenSidebar={sidebarOpen} onCloseSidebar={handleSidebarClose} />
      <Navbar onOpenSidebar={handleSidebarOpen} />
      <MainStyled>
        <Suspense fallback={<LoadingIndicator />}>
          <Outlet />
        </Suspense>
      </MainStyled>
    </SSEContext.Provider>
  );
};

export default SecureZone;
