import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import useConfiguration from '../hooks/useConfiguration';
import Icon from './Icon';

const StyledSwitch = styled(Box)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'light'
      ? theme.palette.grey['200']
      : theme.palette.grey['800'],
  display: 'flex',
  alignItems: 'center',
  borderRadius: '50px',
  gap: 15,
  padding: 11,
  position: 'relative',
  cursor: 'pointer',
  zIndex: 1,
  transition: 'background-color .3s ease-in-out',

  '&:before': {
    position: 'absolute',
    content: '""',
    width: 32,
    height: 32,
    backgroundColor:
      theme.palette.mode === 'light'
        ? theme.palette.warning.main
        : theme.palette.info.darker,
    borderRadius: 24,
    zIndex: 0,
    left: 0,
    transform: `translateX(${theme.palette.mode === 'light' ? 6 : 43}px)`,
    transition: 'background-color .3s ease-in-out, transform .3s ease-in-out',
  },

  '& svg': {
    position: 'relative',
    zIndex: 1,
    transition: 'color .3s ease-in-out, fill .3s ease-in-out',
  },
}));

const ThemeSwitcher = () => {
  const { themeMode, onToggleThemeMode } = useConfiguration();

  return (
    <StyledSwitch onClick={onToggleThemeMode}>
      <Icon name="day" color="white" />
      <Icon name="night" color={themeMode === 'dark' ? 'white' : '#919EAB'} />
    </StyledSwitch>
  );
};

export default ThemeSwitcher;
