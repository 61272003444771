import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'src/common/baseQuery';
import { mainApiUrl } from 'src/config';

import type { Currency } from '../../types';

export type FinancialAccount = {
  uid: string;
  balance: number;
  currency: Currency;
  PaymentAccountAmountBlock: Array<{ amount: number }>;
  revolutPaymentAccount: {
    bankIban: string;
    bankBic: string;
    bankAccountNumber: string;
    bankSortCode: string;
    bankBeneficiaryAddress: {
      city: string;
      country: string;
      postcode: string;
      street_line1: string;
      street_line2: string;
    };
  };
};

export type AdjustedFinancialAccount = FinancialAccount & {
  blockedAmount: number;
  balanceInPLN: number;
};

export type FinancialAccountList<T> = {
  data: {
    items: Array<T>;
    exchangeRates: Array<{
      fromCurrency: Currency;
      toCurrency: Currency;
      rate: number;
    }>;
  };
};

export const financialAccountsApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: mainApiUrl }),
  tagTypes: ['FinancialAccounts'],
  reducerPath: 'financialAccountsApi',
  endpoints: (build) => ({
    createFinancialAccount: build.mutation<void, Currency>({
      invalidatesTags: ['FinancialAccounts'],
      query(currency) {
        return {
          url: '/payment/create-account',
          method: 'POST',
          data: {
            currency,
          },
        };
      },
    }),
    getFinancialAccounts: build.query<FinancialAccountList<AdjustedFinancialAccount>, boolean | void>({
      providesTags: ['FinancialAccounts'],
      query(includeExchangeRates = false) {
        return {
          url: `/payment-account/list?includeExchangeRates=${includeExchangeRates}`,
          method: 'GET',
        };
      },
      transformResponse(response: FinancialAccountList<FinancialAccount>) {
        const initialData = response.data;
        return {
          data: {
            items: initialData.items.map((item) => {
              const blockedAmount = item.PaymentAccountAmountBlock.reduce((acc, curr) => acc + curr.amount, 0);
              const exchangeRate = initialData.exchangeRates.find((x) => x.fromCurrency === item.currency);
              const balanceInPLN =
                item.currency === 'PLN' ? item.balance : (exchangeRate?.rate || 1) * item.balance || 0;

              return {
                ...item,
                balance: item.balance - blockedAmount,
                blockedAmount,
                balanceInPLN,
              };
            }),
            exchangeRates: initialData.exchangeRates,
          },
        };
      },
    }),
  }),
});

export const { useCreateFinancialAccountMutation, useGetFinancialAccountsQuery } = financialAccountsApi;
