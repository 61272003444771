import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { sseApiUrl } from '../../config';
import { financesApi } from '../../finances/finances.service';
import { profileApi } from '../../profile/profile.service';
import { supportRequestsApi } from '../../supportRequests/supportRequests.service';
import { commonApi, useLazyGetSSEOneTimeTokenQuery } from '../apis';
import { financialAccountsApi } from '../features/FinancialAccountsWidget/financialAccounts.service';
import { statusBarApi } from '../features/StatusBar/statusBar.service';
import { addLogoutListener, removeLogoutListener } from '../services/authService';
import { changeLanguage } from '../slices';
import SnackbarUtils from '../utils/snackbarUtils';
import { useAppDispatch } from './reduxHooks';

export default function useSSE(appLoaded: boolean) {
  const eventSource = useRef<EventSource | null>(null);
  const reconnectionTryCountRef = useRef(0);
  const [connectionStatus, setConnectionStatus] = useState('disconnected');
  const { t } = useTranslation(['onboarding']);
  const dispatch = useAppDispatch();
  const [getOneTimeToken] = useLazyGetSSEOneTimeTokenQuery();

  const disconnect = () => {
    eventSource.current?.close();
    console.log('SSE closed!');
  };

  const getEventSource = async () => {
    const authTokenResponse = await getOneTimeToken().unwrap();
    const authToken = authTokenResponse?.token;

    if (!authToken) {
      throw new Error('One time token access was unsuccessful');
    }

    return new EventSource(`${sseApiUrl}/user?authorization=${authToken}`);
  };

  const connect = async () => {
    const source = await getEventSource();
    console.log('reconnectionTryCount - ', reconnectionTryCountRef.current);
    setConnectionStatus('connected');

    source.addEventListener('open', () => {
      reconnectionTryCountRef.current = 0;
      console.log('SSE opened!');
      addLogoutListener(disconnect);
    });

    source.addEventListener('error', (event) => {
      if (event.type === 'error') {
        setConnectionStatus('error');
        console.log('SSE error!', JSON.stringify(event));

        setTimeout(() => {
          if (reconnectionTryCountRef.current < 10) {
            disconnect();
            connect();
            reconnectionTryCountRef.current += 1;
          }
        }, (reconnectionTryCountRef.current + 1) * 1000);
      }
    });

    eventSource.current = source;

    return source;
  };

  useEffect(() => {
    if (appLoaded && !eventSource.current) {
      connect().then((source) => {
        source?.addEventListener('topup_delivery_completed', () => {
          SnackbarUtils.success(t('initialPaymentCompleted'));
        });

        source?.addEventListener('user_settings_updated', (e) => {
          dispatch(changeLanguage(JSON.parse(e.data).interfaceLanguage.toLowerCase()));
        });

        source?.addEventListener('exchange_status_updated', () => {
          dispatch(financesApi.util.invalidateTags(['Transactions']));
          dispatch(financialAccountsApi.util.invalidateTags(['FinancialAccounts']));
        });

        source?.addEventListener('financial_operations_updated', () => {
          dispatch(financesApi.util.invalidateTags(['Transactions']));
          dispatch(financialAccountsApi.util.invalidateTags(['FinancialAccounts']));
        });

        source?.addEventListener('organization_life_stage_updated', () => {
          dispatch(commonApi.util.invalidateTags(['UserAndOrg']));
        });

        source?.addEventListener('permits_verification_succeeded', () => {
          dispatch(commonApi.util.invalidateTags(['UserAndOrg']));
        });

        source?.addEventListener('client_life_stage_updated', () => {
          dispatch(commonApi.util.invalidateTags(['UserAndOrg']));
        });

        source?.addEventListener('subscription_payment_succeeded', () => {
          dispatch(statusBarApi.util.invalidateTags(['Subscription']));
          dispatch(financesApi.util.invalidateTags(['Transactions']));
          dispatch(financialAccountsApi.util.invalidateTags(['FinancialAccounts']));
        });

        source?.addEventListener('work_permit_status_updated', () => {
          dispatch(profileApi.util.invalidateTags(['WorkPermit']));
        });

        source?.addEventListener('support_case_new_message', () => {
          dispatch(supportRequestsApi.util.invalidateTags(['SupportRequestDetails', 'SupportRequests']));
        });

        source?.addEventListener('residence_permit_status_updated', () => {
          dispatch(profileApi.util.invalidateTags(['ResidencePermit']));
        });
      });
    }

    return () => {
      removeLogoutListener(disconnect);
    };
  }, [appLoaded]);

  return { eventSource, connectionStatus };
}
