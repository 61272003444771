import type { BaseQueryFn } from '@reduxjs/toolkit/query';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { ApiError } from 'src/common/types';

const DEFAULT_ERROR = {
  error: {
    status: 500,
    code: '0',
    message: 'Unexpected error',
  },
};

export type AxiosBaseQueryArgs = {
  url: string;
  method: AxiosRequestConfig['method'];
  data?: AxiosRequestConfig['data'];
  params?: AxiosRequestConfig['params'];
};

export const axiosBaseQuery =
  ({ baseUrl }: { baseUrl: string } = { baseUrl: '' }): BaseQueryFn<AxiosBaseQueryArgs, unknown, ApiError> =>
  async ({ url, method, data, params }) => {
    try {
      const result = await axios({
        url: url[0] === '/' ? baseUrl + url : url,
        method,
        data,
        params,
      });
      return { data: result.data };
    } catch (err) {
      if (err instanceof AxiosError<ApiError>) {
        if (err.response) {
          return {
            error: {
              status: err.response.status,
              code: err.response.data.code,
              message: err.response.data.message,
            },
          };
        }
      }

      return DEFAULT_ERROR;
    }
  };
