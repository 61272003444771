import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'src/common/baseQuery';
import { mainApiUrl } from 'src/config';

import { TemplatesList } from '../common/features/ContractManager/contractManager.service';
import { Document, PaginatedData } from '../common/types';

export const documentsApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: mainApiUrl }),
  reducerPath: 'documentsApi',
  endpoints: (build) => ({
    getTemplates: build.query<TemplatesList, null>({
      query() {
        return {
          url: '/document-template',
          method: 'GET',
        };
      },
    }),
    getDocuments: build.query<PaginatedData<Document>, null>({
      query() {
        return {
          url: '/document/list',
          method: 'GET',
          params: {
            limit: 10,
            offset: 0,
            filter: [
              {
                field: 'NOT.fileUpload',
                type: 'raweqnull',
                value: 'null',
              },
            ],
            order: [{ field: 'updatedAt', dir: 'desc' }],
          },
        };
      },
    }),
  }),
});

export const { useGetDocumentsQuery, useGetTemplatesQuery } = documentsApi;
