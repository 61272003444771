import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'src/common/baseQuery';
import { mainApiUrl } from 'src/config';

import { Currency, PaginatedCountedData } from '../common/types';

export type ClientType = 'INDIVIDUAL_ENTREPRENEUR' | 'COMPANY';

export type ClientData = {
  email: string;
  legalName: string;
  type: ClientType;
  taxId?: string;
  registrationAddressLine: string;
  registrationAddressLine2?: string;
  registrationAddressCity: string;
  registrationAddressZip: string;
  registrationAddressCountryUid: string;
};

export type VerificationStatus = {
  data: {
    isValid: boolean;
    countryCode: string;
  };
};

export type ClientContractData = {
  signEmail: string;
  documentTemplateUidList: string[];
};

type CreateClientRequest = {
  agreementData?: ClientContractData;
} & ClientData;

export type ClientResponseInvoices = {
  Invoice: Array<{
    status: 'PAID' | 'OVERDUE' | 'ACTIVE';
    total: string;
    amount: string;
    currency: Currency;
    exchangeRatesSnapshot: Array<{
      fromCurrency: Currency;
      rate: number;
      toCurrency: Currency;
    }>;
  }>;
};

type ClientListCounters = {
  active: number;
  deactivated: number;
};

export type ClientResponse = {
  email: string;
  employerAgreementSigned: boolean;
  legalName: string;
  registrationAddressCity: string;
  registrationAddressCountry: {
    name: string;
    uid: string;
  };
  deleted: boolean;
  verified: boolean;
  registrationAddressLine: string;
  registrationAddressLine2: string;
  registrationAddressZip: string;
  taxId: string;
  type: 'INDIVIDUAL_ENTREPRENEUR' | 'COMPANY';
  uid: string;
  hasEmployerAgreement: boolean;
};

type ClientResponseFinancialData = {
  paidInvoices: number;
  pendingInvoices: number;
  receivedAmount: Record<Currency, number>;
  requestedAmount: Record<Currency, number>;
};

type ContractInfo = {
  DocumentAgreement: Array<{
    Document: Array<{
      name: string;
      updatedAt: string;
      fileUpload: { uid: string };
    }>;
    paymentAccountUid: string;
    signEmail: string;
    status: string;
    uid: string;
  }>;
};

export const ClientTypeMap: Record<ClientType, string> = {
  INDIVIDUAL_ENTREPRENEUR: 'companyTypeIndividual',
  COMPANY: 'companyTypeCompany',
};

export type ClientStatus = 'active' | 'deactivated';

export type GetClientsFilters = {
  status?: ClientStatus;
  search?: string;
};

const calculateAmountForCurrencies = (array: ClientResponseInvoices['Invoice']) => {
  return array.reduce((accumulator, currentValue) => {
    if (!accumulator[currentValue.currency]) {
      accumulator[currentValue.currency] = parseFloat(currentValue.amount);
    } else {
      accumulator[currentValue.currency] = accumulator[currentValue.currency] + parseFloat(currentValue.amount);
    }

    return accumulator;
  }, {} as Record<Currency, number>);
};

export const clientsApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: mainApiUrl }),
  reducerPath: 'clientsApi',
  tagTypes: ['Clients', 'ClientDetails'],
  endpoints: (build) => ({
    verifyVat: build.mutation<VerificationStatus, string>({
      query(taxId) {
        return {
          url: '/organization-employer/verify-vat',
          method: 'POST',
          data: {
            taxId,
          },
        };
      },
    }),
    createClient: build.mutation<void, CreateClientRequest>({
      invalidatesTags: ['Clients'],
      query(data) {
        return {
          url: '/organization-employer',
          method: 'POST',
          data,
        };
      },
    }),
    deactivateClient: build.mutation<void, string>({
      invalidatesTags: ['Clients', 'ClientDetails'],
      query(uid) {
        return {
          url: `/organization-employer/${uid}`,
          method: 'DELETE',
        };
      },
    }),
    getClients: build.query<
      { data: Array<ClientResponse & ClientResponseFinancialData>; counters: ClientListCounters },
      GetClientsFilters
    >({
      providesTags: ['Clients'],
      query(data) {
        const filter = [];

        if (data.status) {
          filter.push({
            field: 'deleted',
            type: '=',
            value: data.status !== 'active',
          });
        }

        if (data.search) {
          filter.push({
            field: '!search',
            type: '=',
            value: data.search,
          });
        }

        return {
          url: '/organization-employer/list',
          method: 'GET',
          params: {
            includeCounters: true,
            filter,
          },
        };
      },
      transformResponse(response: PaginatedCountedData<ClientResponseInvoices & ClientResponse, ClientListCounters>) {
        const data = response.data.items.map((clientInfo) => {
          const { Invoice, ...rest } = clientInfo;
          const paidInvoices = Invoice.filter((invoice) => invoice.status === 'PAID');
          const pendingInvoices = Invoice.filter(
            (invoice) => invoice.status === 'ACTIVE' || invoice.status === 'OVERDUE',
          );

          return {
            ...rest,
            paidInvoices: paidInvoices.length,
            pendingInvoices: pendingInvoices.length,
            receivedAmount: calculateAmountForCurrencies(paidInvoices),
            requestedAmount: calculateAmountForCurrencies(pendingInvoices),
          };
        });

        return {
          data,
          counters: response.data.counters,
        };
      },
    }),
    getClientDetails: build.query<{ data: ClientResponse & ContractInfo }, string | undefined>({
      providesTags: ['ClientDetails'],
      query(uid) {
        return {
          url: `/organization-employer/${uid}?includeDeleted=true`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const {
  useVerifyVatMutation,
  useCreateClientMutation,
  useGetClientsQuery,
  useGetClientDetailsQuery,
  useDeactivateClientMutation,
} = clientsApi;
