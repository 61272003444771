import { OptionsObject, useSnackbar, WithSnackbarProps } from 'notistack';
import { useTranslation, UseTranslationResponse } from 'react-i18next';

let useSnackbarRef: WithSnackbarProps;
let errorTranslationsRef: UseTranslationResponse<'errorMessages', ''>;

export const SnackbarUtilsConfigurator: React.FC = () => {
  useSnackbarRef = useSnackbar();
  errorTranslationsRef = useTranslation(['errorMessages']);
  return null;
};

export default {
  success(msg: string, options: OptionsObject = {}) {
    this.toast(msg, { ...options, variant: 'success' });
  },
  warning(msg: string, options: OptionsObject = {}) {
    this.toast(msg, { ...options, variant: 'warning' });
  },
  info(msg: string, options: OptionsObject = {}) {
    this.toast(msg, { ...options, variant: 'info' });
  },
  error(msg: string, options: OptionsObject = {}) {
    this.toast(msg, { ...options, variant: 'error' });
  },
  errorStatus(status: string, options: OptionsObject = {}) {
    this.toast(errorTranslationsRef.t(status), { ...options, variant: 'error' });
  },
  toast(msg: string, options: OptionsObject = {}) {
    useSnackbarRef.enqueueSnackbar(msg, options);
  },
};
