import { Box, Drawer, List, ListItemIcon, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useAppSelector } from 'src/common/hooks/reduxHooks';
import useResponsive from 'src/common/hooks/useResponsive';
import { getActive } from 'src/common/utils/urlUtils';

import Icon, { IconName } from '../Icon';
import Scrollbar from '../Scrollbar';
import CasesWidget from './components/CasesWidget';
import { ListItemStyle, ListItemTextStyle, ListSubheaderStyle } from './styles';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

type Props = {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
};

const Navbar = ({ isOpenSidebar, onCloseSidebar }: Props) => {
  const { pathname } = useLocation();
  const { t } = useTranslation(['sidebar']);
  const { email, organizationStructure, role, firstName, lastName } = useAppSelector((state) => state.common);

  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);

  const renderListItem = (title: string, path: string, iconName: IconName, additionalPath?: string) => {
    const active = getActive(path, pathname) || (additionalPath ? getActive(additionalPath, pathname) : false);

    return (
      <RouterLink to={path} style={{ textDecoration: 'none' }}>
        <ListItemStyle active={active}>
          <ListItemIcon sx={{ color: 'currentcolor', minWidth: '0', mr: 2 }}>
            <Icon name={iconName} />
          </ListItemIcon>

          <ListItemTextStyle
            primary={title}
            primaryTypographyProps={{
              noWrap: true,
              variant: active ? 'subtitle2' : 'body2',
              sx: {
                textDecoration: 'none',
              },
            }}
          />
        </ListItemStyle>
      </RouterLink>
    );
  };

  const renderItemsBasedOnViewer = () => {
    if (role === 'USER') {
      return (
        <>
          {renderListItem(t('overview'), '/', 'overview')}
          {renderListItem(t('documents'), '/documents', 'documents')}
          {renderListItem(t('profile'), '/profile', 'profile')}
          {renderListItem(t('settings'), '/settings', 'settings')}
        </>
      );
    }
    if (organizationStructure === 'SELF_EMPLOYEE') {
      return (
        <>
          {renderListItem(t('overview'), '/', 'overview')}
          {renderListItem(t('documents'), '/documents', 'documents')}
          {renderListItem(t('clients'), '/clients', 'clients')}
          {renderListItem(t('invoices'), '/invoices', 'invoice')}
          {renderListItem(t('finances'), '/finances', 'finance')}
          {renderListItem(t('profile'), '/profile', 'profile')}
          {renderListItem(t('settings'), '/settings', 'settings')}
        </>
      );
    }

    if (organizationStructure === 'COMPANY') {
      return (
        <>
          {renderListItem(t('overview'), '/', 'overview')}
          <ListSubheaderStyle>{t('organisation')}</ListSubheaderStyle>
          {renderListItem(t('employees'), '/employees', 'employees', '/invitation')}
          {renderListItem(t('documents'), '/documents', 'documents')}
          {renderListItem(t('clients'), '/clients', 'clients')}
          {renderListItem(t('invoices'), '/invoices', 'invoice')}
          {renderListItem(t('finances'), '/finances', 'finance')}
          <ListSubheaderStyle>{t('myData')}</ListSubheaderStyle>
          {renderListItem(t('profile'), '/profile', 'profile')}
          {renderListItem(t('settings'), '/settings', 'settings')}
        </>
      );
    }
  };

  const renderContent = (
    <>
      <Stack spacing={3} py={3} px={2.5} flexShrink={0}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Icon name="logo" width="152px" height="44px" />
        </Stack>
      </Stack>
      <Box>
        <List disablePadding sx={{ px: 2 }}>
          {renderItemsBasedOnViewer()}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <CasesWidget email={email} lastName={lastName} firstName={firstName} />
    </>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer open={isOpenSidebar} onClose={onCloseSidebar} PaperProps={{ sx: { width: 280 } }}>
          {renderContent}
        </Drawer>
      )}
      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              overflowX: 'hidden',
              width: 280,
              borderRightStyle: 'dashed',
              bgcolor: 'background.default',
              transition: (theme) =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.standard,
                }),
            },
          }}
        >
          <Scrollbar
            sx={{
              height: '100%',
              '& .simplebar-content': {
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              },
            }}
          >
            {renderContent}
          </Scrollbar>
        </Drawer>
      )}
    </RootStyle>
  );
};

export default Navbar;
