const colors = ['primary', 'info', 'success', 'warning', 'error', 'default'];

type ColorsType = 'primary' | 'info' | 'success' | 'warning' | 'error' | 'default';

const getColor = (displayName: string) => {
  let sum = 0;

  for (const char of displayName.split('')) {
    sum += char.charCodeAt(0);
  }

  return colors[sum % colors.length] as ColorsType;
};

const getCharacters = (displayName: string) => {
  return displayName
    .split(' ')
    .map((x) => x.charAt(0))
    .slice(0, 2)
    .join('')
    .toUpperCase();
};

export default function createAvatar(displayName: string) {
  const displayCharacters = getCharacters(displayName);
  return {
    name: displayCharacters,
    color: getColor(displayName),
  } as const;
}
