import { Box, CircularProgress } from '@mui/material';

const LoadingIndicator = () => {
  return (
    <Box
      right={0}
      bottom={0}
      zIndex={9999}
      width="100%"
      height="100%"
      position="fixed"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress color="primary" />
    </Box>
  );
};

export default LoadingIndicator;
