import useMediaQuery from '@mui/material/useMediaQuery';
import { createContext, ReactNode } from 'react';

import { defaultMode } from '../../config';
import useLocalStorage from '../hooks/useLocalStorage';
import { ConfigurationContextProps, ThemeMode } from '../types';

const initialState: ConfigurationContextProps = {
  themeMode: defaultMode,
  onToggleMode: () => null,
  onChangeMode: () => null,
};

const ConfigurationContext = createContext(initialState);

type ConfigurationProviderProps = {
  children: ReactNode;
};

function ConfigurationProvider({ children }: ConfigurationProviderProps) {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [themeMode, setThemeMode] = useLocalStorage(
    'themeMode',
    prefersDarkMode ? 'dark' : 'light',
  );

  const onToggleMode = () => {
    setThemeMode(themeMode === 'light' ? 'dark' : 'light');
  };

  const onChangeMode = (mode: ThemeMode) => {
    setThemeMode(mode);
  };

  return (
    <ConfigurationContext.Provider
      value={{
        themeMode,
        onToggleMode,
        onChangeMode,
      }}
    >
      {children}
    </ConfigurationContext.Provider>
  );
}

export { ConfigurationContext, ConfigurationProvider };
