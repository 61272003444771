import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'src/common/baseQuery';
import { mainApiUrl } from 'src/config';

import { ClientResponse } from '../clients/clients.service';
import { Currency, PaginatedData } from '../common/types';
import { InvoiceCompany } from './invoices.types';

type MutateInvoiceBase = {
  paymentAccountUid: string;
  dueDate: Date | null;
  saleDate: Date | null;
  employerUid: string | null;
  invoiceItems: Array<{
    serviceType: string;
    description: string;
    quantity: number;
    unitPrice: number;
    unit: string;
    unitDescription: string;
  }>;
};

type InvoiceCreateRequest = MutateInvoiceBase & {
  isDraft: boolean;
};

type InvoiceUpdateRequest = MutateInvoiceBase & {
  saveAsActive: boolean;
  uid: string;
};

type InvoiceListItem = {
  amount: string;
  total: string;
  createdAt: string;
  dueDate: string;
  currency: Currency;
  employer: {
    uid: string;
    legalName: string;
    email: string;
  };
  exchangeRatesSnapshot: Array<{
    rate: number;
    toCurrency: Currency;
    fromCurrency: Currency;
  }>;
  issueDate: string;
  number: string;
  paymentUid: null;
  status: InvoiceStatus;
  uid: string;
  updatedAt: string;
};

type InvoiceItem = {
  amount: string;
  currency: Currency;
  description: string;
  quantity: number;
  total: string;
  uid: string;
  unitDescription: string;
  unitPrice: string;
};

type InvoiceDetails = {
  invoice: {
    InvoiceItem: Array<InvoiceItem>;
    OrganizationPaymentAccountMap: {
      paymentAccountUid: string;
    };
    amount: string;
    currency: Currency;
    dueDate: string;
    issueDate: string;
    number: string;
    saleDate: string;
    sentAt: string | null;
    status: InvoiceStatus;
    total: string;
    uid: string;
    vatAmount: string;
    exchangeRatesSnapshot: Array<{
      rate: number;
      toCurrency: Currency;
      fromCurrency: Currency;
    }>;
    employer: {
      uid: string;
      email: string;
      legalName: string;
      registrationAddressCity: string;
      registrationAddressCountry: {
        uid: string;
        name: string;
      };
      registrationAddressZip: string;
      registrationAddressLine: string;
      taxId: string | null;
      type: 'INDIVIDUAL_ENTREPRENEUR' | 'COMPANY';
    };
  };
  payment: null | {
    amount: number;
    createdAt: string;
    description: string;
  };
};

export enum InvoiceStatus {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  OVERDUE = 'OVERDUE',
  PAID = 'PAID',
  ARCHIVED = 'ARCHIVED',
}

export type InvoiceCounters = Record<InvoiceStatus, number>;

export type GetInvoicesFilters = {
  status?: InvoiceStatus;
  issueDate?: string;
  dueDate?: string;
  search?: string;
};

export type PaymentToLink = {
  amount: string;
  createdAt: string;
  currency: Currency;
  description: string;
  invoiceUid: null;
  status: string;
  uid: string;
  weight: number;
};

type PaymentsToLinkTransformed = {
  suitablePayments: Array<PaymentToLink>;
  receivedPayments: Array<PaymentToLink>;
};

export const invoicesApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: mainApiUrl }),
  reducerPath: 'invoicesApi',
  tagTypes: ['Invoices', 'Invoice'],
  endpoints: (build) => ({
    getInvoices: build.query<
      { data: { items: Array<InvoiceListItem>; counters: InvoiceCounters } },
      GetInvoicesFilters
    >({
      providesTags: ['Invoices'],
      query(data) {
        const filter = [];

        if (data.status) {
          filter.push({
            field: 'status',
            type: '=',
            value: data.status,
          });
        }

        if (data.issueDate) {
          filter.push({
            field: 'issueDate',
            type: '>=',
            value: data.issueDate,
          });
        }

        if (data.dueDate) {
          filter.push({
            field: 'dueDate',
            type: '<=',
            value: data.dueDate,
          });
        }

        if (data.search) {
          filter.push({
            field: '!search',
            type: '=',
            value: data.search[0] === '#' ? data.search.substring(1) : data.search,
          });
        }

        return {
          url: '/invoice/list?includeCounters=true',
          method: 'GET',
          params: {
            filter,
            order: [{ field: 'createdAt', dir: 'desc' }],
          },
        };
      },
    }),
    getInvoiceById: build.query<{ data: InvoiceDetails }, string | undefined>({
      providesTags: ['Invoice'],
      query(id) {
        return {
          url: `/invoice/${id}`,
          method: 'GET',
        };
      },
    }),
    getClients: build.query<InvoiceCompany[], null>({
      query() {
        return {
          url: '/organization-employer/list',
          method: 'GET',
          params: {
            filter: [
              {
                field: 'deleted',
                type: '=',
                value: false,
              },
              {
                field: '!active',
                type: '=',
                value: 'true',
              },
            ],
          },
        };
      },
      transformResponse(response: { data: { items: ClientResponse[] } }) {
        if (!response || !response.data) {
          return [];
        }

        return response.data.items.map((item) => ({
          id: item.uid,
          name: item.legalName,
          email: item.email,
          taxId: item.taxId,
          entityType: item.type,
          countryUid: item.registrationAddressCountry.uid,
          address: `${item.registrationAddressLine}, ${item.registrationAddressZip} ${item.registrationAddressCity}, ${item.registrationAddressCountry.name}`,
        }));
      },
    }),
    createInvoice: build.mutation<void, InvoiceCreateRequest>({
      invalidatesTags: ['Invoices'],
      query({ isDraft, ...rest }) {
        return {
          url: `/invoice?isDraft=${isDraft}`,
          method: 'POST',
          data: rest,
        };
      },
    }),
    updateInvoice: build.mutation<void, InvoiceUpdateRequest>({
      invalidatesTags: ['Invoices'],
      query({ saveAsActive, uid, ...rest }) {
        return {
          url: `/invoice/${uid}?activate=${saveAsActive}`,
          method: 'PATCH',
          data: rest,
        };
      },
    }),
    deleteDraft: build.mutation<void, string>({
      invalidatesTags: ['Invoices'],
      query(uid) {
        return {
          url: `/invoice/${uid}`,
          method: 'DELETE',
        };
      },
    }),
    sendInvoice: build.mutation<void, string>({
      invalidatesTags: ['Invoice'],
      query(uid) {
        return {
          url: `/invoice/send/${uid}`,
          method: 'POST',
        };
      },
    }),
    getPaymentCandidates: build.query<PaymentsToLinkTransformed, string>({
      query(uid) {
        return {
          url: `/invoice/payment-candidates/${uid}`,
          method: 'GET',
        };
      },
      transformResponse(response: PaginatedData<PaymentToLink>): PaymentsToLinkTransformed {
        const suitablePayments = response.data.items.filter((payment) => payment.weight >= 20);
        const receivedPayments = response.data.items.filter((payment) => payment.weight < 20);
        return { suitablePayments, receivedPayments };
      },
    }),
  }),
});

export const {
  useCreateInvoiceMutation,
  useGetInvoicesQuery,
  useGetClientsQuery,
  useGetInvoiceByIdQuery,
  useSendInvoiceMutation,
  useGetPaymentCandidatesQuery,
  useUpdateInvoiceMutation,
  useDeleteDraftMutation,
} = invoicesApi;
