import { Theme } from '@mui/material/styles';

export default function CssBaseline(theme: Theme) {
  return {
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          margin: 0,
          padding: 0,
          boxSizing: 'border-box',
        },
        html: {
          width: '100%',
          height: '100%',
          WebkitOverflowScrolling: 'touch',
        },
        body: {
          width: '100%',
          height: '100%',
        },
        '#root': {
          width: '100%',
          height: '100%',
        },
        input: {
          '&[type=number]': {
            MozAppearance: 'textfield',
            '&::-webkit-outer-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
            '&::-webkit-inner-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
          },
          '&:-webkit-autofill': {
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: `${theme.palette.text.primary} !important`,
            transition: 'background-color 5000s ease-in-out 0s',
            boxShadow: `inset 0 0 20px 20px ${theme.palette.mode === 'light' ? '#fff' : 'transparent'} !important`,
          },
          '&:-webkit-autofill:hover': {
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: `${theme.palette.text.primary} !important`,
            transition: 'background-color 5000s ease-in-out 0s',
            boxShadow: `inset 0 0 20px 20px ${theme.palette.mode === 'light' ? '#fff' : 'transparent'} !important`,
          },
          '&:-webkit-autofill:focus  ': {
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: `${theme.palette.text.primary} !important`,
            transition: 'background-color 5000s ease-in-out 0s',
            boxShadow: `inset 0 0 20px 20px ${theme.palette.mode === 'light' ? '#fff' : 'transparent'} !important`,
          },
          '&:-webkit-autofill:active': {
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: `${theme.palette.text.primary} !important`,
            transition: 'background-color 5000s ease-in-out 0s',
            boxShadow: `inset 0 0 20px 20px ${theme.palette.mode === 'light' ? '#fff' : 'transparent'} !important`,
          },
        },
        img: {
          display: 'block',
          maxWidth: '100%',
        },
      },
    },
  };
}
