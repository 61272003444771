import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Box, Button, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import Icon from '../Icon';
import NotFoundIllustration from '../Illustrations/NotFoundIllustration';

function NotFound() {
  return (
    <>
      <Box px={2.5} py={3} position="absolute">
        <Icon name="logo" width="152px" height="44px" />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        maxWidth={400}
        m="auto"
        textAlign="center"
        py={12}
        justifyContent="center"
        minHeight="100vh"
      >
        <NotFoundIllustration />
        <Typography variant="h3" my={7}>
          Sorry, Page Not Found!
        </Typography>
        <Button
          size="large"
          variant="outlined"
          color="inherit"
          component={RouterLink}
          to="/"
          startIcon={<KeyboardArrowLeftIcon />}
        >
          Back to overview
        </Button>
      </Box>
    </>
  );
}

export default NotFound;
