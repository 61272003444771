import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'src/common/baseQuery';
import { mainApiUrl } from 'src/config';

type TokenForLibraryResponse = {
  data: {
    kycCheck: {
      status: string;
    };
    sumsubToken: string;
  };
  success: boolean;
  code?: number;
};

type WorkPermitResponse = {
  data: {
    source: string;
    status: string;
  };
};

export const kycApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: mainApiUrl }),
  reducerPath: 'kycApi',
  endpoints: (build) => ({
    retrieveTokenForLibrary: build.mutation<TokenForLibraryResponse, void>({
      query() {
        return {
          url: '/kyc/start',
          method: 'POST',
        };
      },
    }),
    getWorkPermitInfo: build.query<WorkPermitResponse, void>({
      query() {
        return {
          url: '/work-permit',
          method: 'GET',
        };
      },
    }),
    createKYCSupportCase: build.mutation<void, string[]>({
      query(files) {
        return {
          url: '/support-case',
          method: 'POST',
          data: {
            message: 'Documents verification request',
            fileUploadUidList: files,
            request: 'PERMIT_CHECK_REQUEST',
            mode: 'REQUEST',
            caseType: 'permits_check_request',
          },
        };
      },
    }),
  }),
});

export const { useRetrieveTokenForLibraryMutation, useGetWorkPermitInfoQuery, useCreateKYCSupportCaseMutation } =
  kycApi;
