import { configureStore, isRejectedWithValue, Middleware } from '@reduxjs/toolkit';
import { authApi } from 'src/auth/auth.service';
import { clientsApi } from 'src/clients/clients.service';
import { onboardingApi } from 'src/common/features/Onboarding/onboarding.service';
import { contractsReviewApi } from 'src/contractsReview/contractsReview.service';
import { documentsApi } from 'src/documents/documents.service';
import { employeesApi } from 'src/employees/employees.service';
import { financesApi } from 'src/finances/finances.service';
import { invoicesApi } from 'src/invoices/invoices.service';
import { kycApi } from 'src/kyc/kyc.service';
import { prepaidBalanceApi } from 'src/prepaidBalance/prepaidBalance.service';
import { profileApi } from 'src/profile/profile.service';
import { settingsApi } from 'src/settings/settings.service';

import { supportRequestsApi } from '../supportRequests/supportRequests.service';
import { commonApi } from './apis';
import { contractManagerApi } from './features/ContractManager/contractManager.service';
import { financialAccountsApi } from './features/FinancialAccountsWidget/financialAccounts.service';
import { statusBarApi } from './features/StatusBar/statusBar.service';
import commonSlices from './slices';
import SnackbarUtils from './utils/snackbarUtils';

const INVALID_CODES = [1, 8, 9, 34];

const rtkQueryErrorLogger: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    if (
      action.payload.status !== 401 &&
      action.payload.status !== 404 &&
      !INVALID_CODES.includes(action.payload.code)
    ) {
      SnackbarUtils.errorStatus(action.payload.code);
    }
  }

  return next(action);
};

export const store = configureStore({
  reducer: {
    [commonApi.reducerPath]: commonApi.reducer,
    [settingsApi.reducerPath]: settingsApi.reducer,
    [onboardingApi.reducerPath]: onboardingApi.reducer,
    [contractsReviewApi.reducerPath]: contractsReviewApi.reducer,
    [prepaidBalanceApi.reducerPath]: prepaidBalanceApi.reducer,
    [profileApi.reducerPath]: profileApi.reducer,
    [employeesApi.reducerPath]: employeesApi.reducer,
    [kycApi.reducerPath]: kycApi.reducer,
    [documentsApi.reducerPath]: documentsApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [contractManagerApi.reducerPath]: contractManagerApi.reducer,
    [clientsApi.reducerPath]: clientsApi.reducer,
    [financialAccountsApi.reducerPath]: financialAccountsApi.reducer,
    [financesApi.reducerPath]: financesApi.reducer,
    [invoicesApi.reducerPath]: invoicesApi.reducer,
    [supportRequestsApi.reducerPath]: supportRequestsApi.reducer,
    [statusBarApi.reducerPath]: statusBarApi.reducer,
    common: commonSlices,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      rtkQueryErrorLogger,
      commonApi.middleware,
      settingsApi.middleware,
      onboardingApi.middleware,
      contractsReviewApi.middleware,
      prepaidBalanceApi.middleware,
      profileApi.middleware,
      employeesApi.middleware,
      kycApi.middleware,
      documentsApi.middleware,
      authApi.middleware,
      contractManagerApi.middleware,
      clientsApi.middleware,
      financialAccountsApi.middleware,
      financesApi.middleware,
      invoicesApi.middleware,
      supportRequestsApi.middleware,
      statusBarApi.middleware,
    ]),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
