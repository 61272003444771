import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'src/common/baseQuery';
import { mainApiUrl } from 'src/config';

type PrepaidResponse = {
  data: {
    topup: {
      publicId: string;
      uid: string;
    };
  };
};

export const prepaidBalanceApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: mainApiUrl }),
  reducerPath: 'prepaidBalanceApi',
  endpoints: (build) => ({
    payByCard: build.mutation<PrepaidResponse, number>({
      query(amount) {
        return {
          url: '/payment/topup',
          method: 'POST',
          data: {
            amount,
          },
        };
      },
    }),
  }),
});

export const { usePayByCardMutation } = prepaidBalanceApi;
