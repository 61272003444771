import { createApi } from '@reduxjs/toolkit/query/react';
import axios from 'axios';
import { axiosBaseQuery } from 'src/common/baseQuery';
import { authApiUrl, mainApiUrl } from 'src/config';

import { ApiError } from '../common/types';

export type SingInPasswordResponse =
  | {
      code: string;
      mfaRequired: true;
    }
  | {
      mfaRequired: false;
      access_token: string;
      refresh_token: string;
      expires_in: number;
    };

export interface SignInPasswordPayload {
  email: string;
  password: string;
}

export interface SignInMFAPayload {
  token: string;
  code: string;
}

export interface SingUpResponse {
  mfaRequired: false;
  access_token: string;
  refresh_token: string;
  expires_in: number;
}

export interface SignUpPayload {
  email: string;
  password: string;
}

export interface ForgotPasswordPayload {
  email: string;
}

export interface NewPasswordPayload {
  token: string;
  password: string;
}

export interface AcceptInvitationPayload {
  invitation_token: string;
  password: string;
}

export interface InvitationStatusResponse {
  email: string;
  status: 'success' | 'failure';
}

export interface ChangePasswordStatusResponse {
  email: string;
}

export const authApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: authApiUrl }),
  reducerPath: 'auth',
  endpoints: (build) => ({
    signInPassword: build.mutation<SingInPasswordResponse, SignInPasswordPayload>({
      query({ email, password }) {
        return {
          url: '/sign-in',
          method: 'POST',
          data: {
            email,
            password,
          },
        };
      },
    }),
    signInMFA: build.mutation<SingInPasswordResponse, SignInMFAPayload>({
      query(data) {
        return {
          url: '/mfa/verify-mfa-sign-in',
          method: 'POST',
          data,
        };
      },
    }),
    signUp: build.mutation<SingUpResponse, SignUpPayload>({
      query({ email, password }) {
        return {
          url: '/sign-up',
          method: 'POST',
          data: {
            email,
            password,
          },
        };
      },
    }),
    forgotPassword: build.mutation<unknown, ForgotPasswordPayload>({
      query({ email }) {
        return {
          url: '/forgot-password',
          method: 'POST',
          data: { email },
        };
      },
    }),
    getChangePasswordState: build.query<ChangePasswordStatusResponse, string>({
      query(token) {
        return {
          url: `/change-password-state?token=${token}`,
          method: 'POST',
        };
      },
    }),
    changePassword: build.mutation<unknown, NewPasswordPayload>({
      query({ token, password }) {
        return {
          url: '/new-password',
          method: 'POST',
          data: { token, password },
        };
      },
    }),
    acceptInvitation: build.mutation<SingInPasswordResponse, AcceptInvitationPayload>({
      query(data) {
        return {
          url: '/invitation/accept',
          method: 'POST',
          data,
        };
      },
    }),
    getInvitationState: build.query<InvitationStatusResponse, string>({
      async queryFn(token) {
        try {
          const [apiToken, authToken] = await Promise.all([
            axios.get(`${mainApiUrl}/invitation/${encodeURIComponent(token)}`),
            axios.post(`${authApiUrl}/invitation/details`, {
              token,
            }),
          ]);

          return {
            data: {
              email: apiToken.data.data.email,
              status: authToken.data.status,
            },
          };
        } catch (err) {
          return { error: err as ApiError };
        }
      },
    }),
    verifyEmail: build.mutation<unknown, string>({
      query(token) {
        return {
          url: `/verify?token=${token}`,
          method: 'POST',
        };
      },
    }),
  }),
});

export const {
  useSignInPasswordMutation,
  useSignInMFAMutation,
  useSignUpMutation,
  useForgotPasswordMutation,
  useChangePasswordMutation,
  useGetInvitationStateQuery,
  useAcceptInvitationMutation,
  useVerifyEmailMutation,
  useGetChangePasswordStateQuery,
} = authApi;
