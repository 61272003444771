export const authApiUrl = process.env.REACT_APP_AUTH_API_URL || '';
export const mainApiUrl = process.env.REACT_APP_USER_API_URL || '';
export const sseApiUrl = process.env.REACT_APP_SSE_API_URL || '';

export const allLangs = [
  {
    label: 'English',
    value: 'en',
    icon: 'englishFlag',
  },
  {
    label: 'Русский',
    value: 'ru',
    icon: 'russianFlag',
  },
];

export const defaultLang = allLangs[0];

export const defaultMode = 'dark';
