import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { allLangs, defaultLang } from '../../config';
import { ConfigurationContext } from '../contexts/ConfigurationContext';

export default function useConfiguration() {
  const { i18n } = useTranslation();
  const langStorage = localStorage.getItem('i18nextLng');
  const { onToggleMode, themeMode } = useContext(ConfigurationContext);

  const currentLanguage =
    allLangs.find((lang) => lang.value === langStorage) || defaultLang;

  const handleChangeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  return {
    onChangeLanguage: handleChangeLanguage,
    currentLanguage,
    onToggleThemeMode: onToggleMode,
    themeMode,
  };
}
