import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'src/common/baseQuery';
import { mainApiUrl } from 'src/config';

import { PaginatedData, SearchParams } from '../common/types';

export type MessageType =
  | 'COMMON'
  | 'ATTACHMENTS'
  | 'DOCUMENTS'
  | 'TEXT'
  | 'TEXT_WITH_ATTACHMENTS'
  | 'TEXT_WITH_DOCUMENTS';

type SupportTag = {
  uid: string;
  name: string;
};

type SupportRequestCreatePayload = {
  message: string;
  supportTagUid: string;
  fileUploadUidList?: string[];
};

type SupportMember = {
  firstName: string;
  lastName: string;
  uid: string;
};

export type SupportChatMessage = {
  uid: string;
  type: MessageType;
  text: string;
  owner: 'SUPPORT' | 'CLIENT' | 'SYSTEM';
  sentAt: string;
  createdAt: string;
  DocumentsOnSupportChatMessages: [];
  FileUploadsOnSupportChatMessages: Array<ChatMessageAttachment>;
  supportMember: null | SupportMember;
};

export type ChatMessageAttachment = {
  fileUpload: {
    extension: string;
    key: string;
    mimetype: string;
    originalName: string;
    uid: string;
  };
};

export type SupportRequest = {
  canBeReopened: boolean;
  createdAt: string;
  status: 'OPEN' | 'PROCESSING' | 'CLOSED';
  supportTag: {
    uid: string;
    name: string;
    deleted: false;
  };
  uid: string;
  lastMessageSentAt: null | string;
  updatedAt: string;
  clientLastReadDate: string | null;
  SupportChatMessage: Array<SupportChatMessage>;
  FileUploadsOnSupportCases: Array<ChatMessageAttachment>;
};

type SupportRequestSendMessagePayload = {
  supportCaseUid: string;
  text?: string;
  fileUploadUidList?: Array<string>;
  type: MessageType;
};

export const supportRequestsApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: mainApiUrl }),
  reducerPath: 'supportRequestsApi',
  tagTypes: ['SupportRequests', 'SupportRequestDetails'],
  endpoints: (build) => ({
    getSupportTags: build.query<PaginatedData<SupportTag>, boolean>({
      query(onlyInternal) {
        return {
          url: '/support-tag/list',
          method: 'GET',
          params: onlyInternal
            ? {
                filter: [
                  {
                    field: 'type',
                    type: '!=',
                    value: 'INTERNAL',
                  },
                ],
              }
            : undefined,
        };
      },
    }),
    getSupportRequests: build.query<PaginatedData<SupportRequest>, SearchParams>({
      providesTags: ['SupportRequests'],
      query(params) {
        return {
          url: '/support-case/list',
          method: 'GET',
          params,
        };
      },
    }),
    getRequestDetails: build.query<{ data: SupportRequest }, string>({
      providesTags: ['SupportRequestDetails'],
      query(uid) {
        return {
          url: `/support-case/${uid}`,
          method: 'GET',
        };
      },
      transformResponse(response: { data: SupportRequest }) {
        if (
          response.data.FileUploadsOnSupportCases &&
          response.data.FileUploadsOnSupportCases.length &&
          response.data.SupportChatMessage &&
          response.data.SupportChatMessage.length
        ) {
          response.data.SupportChatMessage[0].FileUploadsOnSupportChatMessages.push(
            ...response.data.FileUploadsOnSupportCases,
          );
        }

        return response;
      },
    }),
    createSupportRequest: build.mutation<{ metadata: { uid: string } }, SupportRequestCreatePayload>({
      invalidatesTags: ['SupportRequests'],
      query(params) {
        return {
          url: '/support-case',
          method: 'POST',
          data: {
            ...params,
            mode: 'CHAT',
          },
        };
      },
    }),
    sendMessage: build.mutation<void, SupportRequestSendMessagePayload>({
      invalidatesTags: ['SupportRequestDetails'],
      query(params) {
        return {
          url: '/support-chat-message',
          method: 'POST',
          data: {
            ...params,
            sentAt: new Date(),
          },
        };
      },
    }),
    markCaseRead: build.mutation<void, string>({
      invalidatesTags: ['SupportRequests'],
      query(uid) {
        return {
          url: `/support-case/mark-read/${uid}`,
          method: 'POST',
        };
      },
    }),
  }),
});

export const {
  useGetSupportTagsQuery,
  useCreateSupportRequestMutation,
  useGetSupportRequestsQuery,
  useGetRequestDetailsQuery,
  useSendMessageMutation,
  useMarkCaseReadMutation,
} = supportRequestsApi;
