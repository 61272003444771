import { createApi } from '@reduxjs/toolkit/query/react';
import { OrganisationStructure } from 'src/common/apis';
import { axiosBaseQuery } from 'src/common/baseQuery';
import { mainApiUrl } from 'src/config';

import type { Document } from '../../types';

type GetContractsResponse = {
  data: {
    cooperationAgreement: {
      DocumentTemplatesOnDocumentAgreements: { documentTemplate: Document }[];
    };
    cooperationAgreementAdditionTemplates: Document[];
  };
};

export const onboardingApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: mainApiUrl }),
  reducerPath: 'onboardingApi',
  endpoints: (build) => ({
    changeOrganisationStructure: build.mutation<void, OrganisationStructure>({
      query(structureType) {
        return {
          url: '/organization/structure-type',
          method: 'PATCH',
          data: {
            structureType,
          },
        };
      },
    }),
    sendDocumentsForSignature: build.mutation<null, void>({
      query() {
        return {
          url: '/document-agreement/cooperation-agreement/initiate',
          method: 'POST',
        };
      },
    }),
    getContractsToSign: build.query<GetContractsResponse, void>({
      query() {
        return {
          url: '/document-agreement/cooperation-agreement',
          method: 'GET',
        };
      },
    }),
  }),
});

export const {
  useChangeOrganisationStructureMutation,
  useGetContractsToSignQuery,
  useSendDocumentsForSignatureMutation,
} = onboardingApi;
