import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'src/common/baseQuery';
import { mainApiUrl } from 'src/config';

import { Currency, PaginatedCountedData, PaginatedData, SearchParams } from '../common/types';
import type { TransactionItem } from './finances.types';

type CurrencyExchangeRequest = {
  fromCurrency: Currency;
  toCurrency: Currency;
  amount: string;
};

type ExchangeRequest = {
  amount: number;
  toRevolutPaymentAccountUid: string;
  fromRevolutPaymentAccountUid: string;
};

type LinkInvoiceToPaymentRequest = {
  paymentUid: string;
  invoiceUid: string;
};

export type InvoicesToAttach = {
  amount: string;
  total: string;
  currency: Currency;
  dueDate: string;
  issueDate: string;
  legalName: string;
  number: string;
  uid: string;
  weight: number;
};

type InvoicesToAttachTransformed = {
  suitableInvoices: Array<InvoicesToAttach>;
  openInvoices: Array<InvoicesToAttach>;
};

type PayoutRequest = {
  clientUid: string;
  workEffectData: {
    richText: string;
    description: string;
  };
  payoutRequestData: [
    {
      amount: number;
      paymentAccountUid: string;
    },
  ];
};

export type TransactionStatus = 'processed' | 'noInvoice';

export type GetTransactionFilters = {
  status?: TransactionStatus;
  account?: string;
  dueDate?: string | null;
  fromDate?: string | null;
};

type ExchangeResponse = {
  data: {
    rate: number;
    fee: {
      amount: number;
      currency: Currency;
    };
    from: {
      amount: number;
      currency: Currency;
    };
    to: {
      amount: number;
      currency: Currency;
    };
  };
};

type FinanceListCounters = {
  noInvoice: number;
  processed: number;
};

export const financesApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: mainApiUrl }),
  reducerPath: 'financesApi',
  tagTypes: ['Transactions'],
  endpoints: (build) => ({
    getTransactions: build.query<
      PaginatedCountedData<TransactionItem, FinanceListCounters>,
      SearchParams & GetTransactionFilters
    >({
      providesTags: ['Transactions'],
      query(data) {
        const filter = [];
        const { status, dueDate, fromDate, account, ...rest } = data;

        if (status) {
          filter.push({
            field: '!status',
            type: '=',
            value: status,
          });
        }

        if (account) {
          filter.push({
            field: '!pa',
            type: '=',
            value: account,
          });
        }

        if (dueDate) {
          filter.push({
            field: 'createdAt',
            type: '<=',
            value: dueDate,
          });
        }

        if (fromDate) {
          filter.push({
            field: 'createdAt',
            type: '>=',
            value: fromDate,
          });
        }

        return {
          url: '/payment/financial-operation',
          method: 'GET',
          params: {
            includeCounters: true,
            filter,
            ...rest,
          },
        };
      },
    }),
    getInvoicesToAttach: build.query<InvoicesToAttachTransformed, string>({
      query(uid) {
        return {
          url: `/payment/invoice-candidates/${uid}`,
          method: 'GET',
        };
      },
      transformResponse(response: PaginatedData<InvoicesToAttach>): InvoicesToAttachTransformed {
        const suitableInvoices = response.data.items.filter((invoice) => invoice.weight >= 20);
        const openInvoices = response.data.items.filter((invoice) => invoice.weight < 20);
        return { suitableInvoices, openInvoices };
      },
    }),
    linkPaymentToInvoice: build.mutation<string, LinkInvoiceToPaymentRequest>({
      invalidatesTags: ['Transactions'],
      query(params) {
        return {
          url: `/payment/link-invoice/${params.invoiceUid}`,
          method: 'POST',
          data: {
            paymentUid: params.paymentUid,
          },
        };
      },
    }),
    getExchangeRates: build.query<ExchangeResponse, CurrencyExchangeRequest>({
      query(params) {
        return {
          url: '/payment/exchange-rate',
          method: 'GET',
          params,
        };
      },
    }),
    exchangeCurrency: build.mutation<string, ExchangeRequest>({
      invalidatesTags: ['Transactions'],
      query(params) {
        return {
          url: '/payment/exchange',
          method: 'POST',
          data: {
            ...params,
            amountType: 'from',
          },
        };
      },
    }),
    createPayoutRequest: build.mutation<string, PayoutRequest>({
      query(params) {
        return {
          url: '/payout-request',
          method: 'POST',
          data: params,
        };
      },
    }),
  }),
});

export const {
  useGetTransactionsQuery,
  useGetExchangeRatesQuery,
  useExchangeCurrencyMutation,
  useGetInvoicesToAttachQuery,
  useLinkPaymentToInvoiceMutation,
  useCreatePayoutRequestMutation,
} = financesApi;
