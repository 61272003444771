import { createApi } from '@reduxjs/toolkit/query/react';
import axios from 'axios';
import { axiosBaseQuery } from 'src/common/baseQuery';
import { authApiUrl, mainApiUrl } from 'src/config';

import { UploadFile } from './components/Upload/Upload.types';
import { ApiError, CountriesList } from './types';

export type AsyncOnboardingStage = 'NONE' | 'PROGRESS' | 'DONE' | 'ERROR';

export type OrganisationStructure = 'SELF_EMPLOYEE' | 'COMPANY';

export interface UserOrgState {
  email: string;
  clientId: string;
  profileId: string;
  userId: string;
  firstName: string;
  lastName: string;
  role: 'USER' | 'MANAGER';
  organizationId: string;
  emailVerified: boolean;
  mfaEnabled: boolean;
  organizationStructure: OrganisationStructure;
  organizationActivated: boolean;
  identityVerified: boolean;
  language: string;
  onboarding: OrganisationOnboarding;
  contractType: string;
  cooperationAgreementSigned: boolean;
  hasUnclearedIncome: boolean;
}

export interface OrganisationOnboarding {
  contractsReviewed: boolean;
  initialProfileFill: boolean;
  initialAmountPaid: AsyncOnboardingStage;
  firstEmployeeInvited: boolean;
  verificationDone: AsyncOnboardingStage;
  permitsVerificationDone: AsyncOnboardingStage;
  contractSigned: AsyncOnboardingStage;
  firstBankAccountAdded: boolean;
  onboardingStatus: 'ACTIVE' | 'FINISHED';
  initialRepresentativeContractSigned: AsyncOnboardingStage;
}

export const commonApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: mainApiUrl }),
  reducerPath: 'commonApi',
  tagTypes: ['UserAndOrg'],
  endpoints: (build) => ({
    getUserAndOrg: build.query<UserOrgState, void>({
      providesTags: ['UserAndOrg'],
      async queryFn() {
        try {
          const [user, me] = await Promise.all([
            axios.get(`${authApiUrl}/current-user`),
            axios.get(`${mainApiUrl}/client/me`),
          ]);

          return {
            data: {
              email: user.data.email,
              clientId: me.data.data.uid,
              userId: me.data.data.User.uid,
              firstName: me.data.data.ClientProfile?.firstName,
              lastName: me.data.data.ClientProfile?.lastName,
              profileId: me.data.data.ClientProfile?.uid,
              role: user.data.role,
              emailVerified: user.data.emailVerified,
              mfaEnabled: user.data.mfaEnabled,
              organizationId: me.data.data.organization.uid,
              organizationStructure: me.data.data.organization.structureType,
              organizationActivated: me.data.data.organization.setOff,
              hasUnclearedIncome: me.data.data.organization.hasUnclearedIncome,
              identityVerified: me.data.data.identityVerified,
              language: me.data.data.User.UserSettings.interfaceLanguage,
              contractType: me.data.data.contractType,
              cooperationAgreementSigned: me.data.data.organization.cooperationAgreementSigned,
              onboarding: {
                contractsReviewed: me.data.data.organization.lifeStage.contractsReviewed,
                initialProfileFill: me.data.data.lifeStage.initialProfileFill,
                initialAmountPaid: me.data.data.organization.lifeStage.initialAmountPaid,
                firstEmployeeInvited: me.data.data.organization.lifeStage.firstEmployeeInvited,
                verificationDone: me.data.data.lifeStage.verificationDone,
                permitsVerificationDone: me.data.data.lifeStage.permitsVerificationDone,
                contractSigned: me.data.data.lifeStage.contractSigned,
                firstBankAccountAdded: me.data.data.lifeStage.firstBankAccountAdded,
                onboardingStatus: me.data.data.lifeStage.onboardingStatus,
                initialRepresentativeContractSigned:
                  me.data.data.organization.lifeStage.initialRepresentativeContractSigned,
              },
            },
          };
        } catch (err) {
          return { error: err as ApiError };
        }
      },
    }),
    uploadFile: build.mutation<string, UploadFile>({
      async queryFn(file) {
        try {
          const uploadInfo = await axios.post(`${mainApiUrl}/file-upload/request`, {
            originalName: file.content.name,
            extension: file.content.name.split('.').at(-1),
            mimetype: file.content.type,
          });

          await axios.put(uploadInfo.data.data.url, file.content, {
            headers: { 'Content-Type': file.content.type },
            transformRequest: (data, headers) => {
              delete headers?.['Authorization'];
              return data;
            },
          });

          await axios.post(`${mainApiUrl}/file-upload/confirm`, {
            size: file.content.size,
            uid: uploadInfo.data.data.uid,
          });

          return {
            data: uploadInfo.data.data.uid,
          };
        } catch (err) {
          return { error: err as ApiError };
        }
      },
    }),
    getSSEOneTimeToken: build.query<{ token: string }, void>({
      query() {
        return {
          url: `${authApiUrl}/token/one-time`,
          method: 'POST',
        };
      },
    }),
    getCountries: build.query<CountriesList, void>({
      query() {
        return {
          url: '/country',
          method: 'GET',
          params: {
            limit: 500,
            offset: 0,
          },
        };
      },
    }),
    changeLanguage: build.mutation<undefined, string>({
      async queryFn(interfaceLanguage) {
        try {
          await axios.patch(`${mainApiUrl}/user-settings`, {
            interfaceLanguage,
          });

          return {
            data: undefined,
          };
        } catch (err) {
          return { error: err as ApiError };
        }
      },
    }),
    getFileUrl: build.query<{ data: { url: string } }, string>({
      query(fileUid) {
        return {
          url: `/file-upload/view-url/${fileUid}`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const {
  useLazyGetUserAndOrgQuery,
  useGetCountriesQuery,
  useUploadFileMutation,
  useLazyGetSSEOneTimeTokenQuery,
  useLazyGetFileUrlQuery,
} = commonApi;
