import { Box, BoxProps } from '@mui/material';
import { styled } from '@mui/material/styles';

import {
  AlertError,
  AlertInfo,
  AlertSuccess,
  AlertWarning,
  AttachIcon,
  BelarussianFlagIcon,
  CheckboxCheckedIcon,
  CheckboxIcon,
  CheckboxIndeterminateIcon,
  ClientIcon,
  CloseIcon,
  DayIcon,
  DocumentsIcon,
  DownloadIcon,
  EditIcon,
  EmployeesIcon,
  EnglishFlagIcon,
  EyeIcon,
  FinancesIcon,
  InvoiceIcon,
  KewstonLogo,
  LeftChevron,
  LockIcon,
  NightIcon,
  OpenNewPageIcon,
  OverviewIcon,
  PolishFlagIcon,
  PrintIcon,
  ProfileIcon,
  RightChevron,
  RussianFlagIcon,
  SendMessageIcon,
  SettingsIcon,
  SidebarCollapse,
  TrashIcon,
  UkranianFlagIcon,
} from './Icons';

export type IconName =
  | 'leftChevron'
  | 'logo'
  | 'profile'
  | 'overview'
  | 'settings'
  | 'invoice'
  | 'documents'
  | 'employees'
  | 'finance'
  | 'clients'
  | 'russianFlag'
  | 'rightChevron'
  | 'englishFlag'
  | 'polishFlag'
  | 'ukranianFlag'
  | 'belarussianFlag'
  | 'alertError'
  | 'alertWarning'
  | 'alertSuccess'
  | 'alertInfo'
  | 'sidebarCollapse'
  | 'checkbox'
  | 'checkboxChecked'
  | 'checkboxIndeterminate'
  | 'lock'
  | 'eye'
  | 'download'
  | 'day'
  | 'night'
  | 'sendMessage'
  | 'attach'
  | 'print'
  | 'edit'
  | 'trash'
  | 'openNewPage'
  | 'close';

const IconStyled = styled(Box)<{ width: string; height: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  & svg { fill: currentColor; width: ${(props) => props.width}; height: ${(props) => props.height}; },
`;

interface Props extends Omit<BoxProps, 'width' | 'height'> {
  name: IconName;
  width?: string;
  height?: string;
  color?: string;
}

const IconMap: Record<IconName, JSX.Element> = {
  logo: <KewstonLogo />,
  finance: <FinancesIcon />,
  russianFlag: <RussianFlagIcon />,
  englishFlag: <EnglishFlagIcon />,
  polishFlag: <PolishFlagIcon />,
  ukranianFlag: <UkranianFlagIcon />,
  belarussianFlag: <BelarussianFlagIcon />,
  alertError: <AlertError />,
  alertWarning: <AlertWarning />,
  alertSuccess: <AlertSuccess />,
  alertInfo: <AlertInfo />,
  close: <CloseIcon />,
  sidebarCollapse: <SidebarCollapse />,
  checkbox: <CheckboxIcon />,
  checkboxChecked: <CheckboxCheckedIcon />,
  checkboxIndeterminate: <CheckboxIndeterminateIcon />,
  overview: <OverviewIcon />,
  profile: <ProfileIcon />,
  settings: <SettingsIcon />,
  invoice: <InvoiceIcon />,
  documents: <DocumentsIcon />,
  employees: <EmployeesIcon />,
  clients: <ClientIcon />,
  leftChevron: <LeftChevron />,
  eye: <EyeIcon />,
  download: <DownloadIcon />,
  lock: <LockIcon />,
  day: <DayIcon />,
  night: <NightIcon />,
  rightChevron: <RightChevron />,
  sendMessage: <SendMessageIcon />,
  attach: <AttachIcon />,
  print: <PrintIcon />,
  edit: <EditIcon />,
  openNewPage: <OpenNewPageIcon />,
  trash: <TrashIcon />,
};

// eslint-disable-next-line react/display-name
const Icon = ({ name, color, ...rest }: Props) => {
  const width = rest.width || '22px';
  const height = rest.height || '22px';

  return (
    <IconStyled
      component="span"
      width={width}
      height={height}
      display="inline-block"
      color={color || 'currentColor'}
      {...rest}
    >
      {IconMap[name]}
    </IconStyled>
  );
};

export default Icon;
