import { createSlice } from '@reduxjs/toolkit';
import { onboardingApi } from 'src/common/features/Onboarding/onboarding.service';

import { commonApi, UserOrgState } from './apis';

const initialState: UserOrgState = {
  email: '',
  firstName: '',
  lastName: '',
  role: 'USER',
  profileId: '',
  clientId: '',
  userId: '',
  organizationId: '',
  emailVerified: false,
  mfaEnabled: false,
  identityVerified: false,
  organizationStructure: 'SELF_EMPLOYEE',
  organizationActivated: false,
  contractType: '',
  language: 'en',
  cooperationAgreementSigned: false,
  hasUnclearedIncome: false,
  onboarding: {
    contractsReviewed: false,
    initialProfileFill: false,
    initialAmountPaid: 'NONE',
    firstEmployeeInvited: false,
    verificationDone: 'NONE',
    permitsVerificationDone: 'NONE',
    contractSigned: 'NONE',
    firstBankAccountAdded: false,
    onboardingStatus: 'FINISHED',
    initialRepresentativeContractSigned: 'NONE',
  },
};

export const userOrgSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    changeLanguage: (state, action: { payload: string; type: string }) => {
      state.language = action.payload;
    },
    markPrepaymentLoading: (state) => {
      state.onboarding.initialAmountPaid = 'PROGRESS';
    },
    markKycLoading: (state) => {
      state.onboarding.verificationDone = 'PROGRESS';
    },
    markVerificationsLoading: (state) => {
      state.onboarding.permitsVerificationDone = 'PROGRESS';
    },
    markContractsReviewed: (state) => {
      state.onboarding.contractsReviewed = true;
    },
    markContractSignLoading: (state) => {
      state.onboarding.contractSigned = 'PROGRESS';
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(commonApi.endpoints.getUserAndOrg.matchFulfilled, (state, action) => {
        state.email = action.payload.email;
        state.clientId = action.payload.clientId;
        state.userId = action.payload.userId;
        state.firstName = action.payload.firstName;
        state.profileId = action.payload.profileId;
        state.language = action.payload.language.toLowerCase();
        state.lastName = action.payload.lastName;
        state.emailVerified = action.payload.emailVerified;
        state.role = action.payload.role;
        state.mfaEnabled = action.payload.mfaEnabled;
        state.organizationId = action.payload.organizationId;
        state.organizationStructure = action.payload.organizationStructure;
        state.organizationActivated = action.payload.organizationActivated;
        state.onboarding = action.payload.onboarding;
        state.identityVerified = action.payload.identityVerified;
        state.contractType = action.payload.contractType;
        state.cooperationAgreementSigned = action.payload.cooperationAgreementSigned;
        state.hasUnclearedIncome = action.payload.hasUnclearedIncome;
      })
      .addMatcher(onboardingApi.endpoints.changeOrganisationStructure.matchFulfilled, (state, action) => {
        state.organizationStructure = action.meta.arg.originalArgs;
      });
  },
});

export const {
  changeLanguage,
  markPrepaymentLoading,
  markKycLoading,
  markContractsReviewed,
  markContractSignLoading,
  markVerificationsLoading,
} = userOrgSlice.actions;

export default userOrgSlice.reducer;
